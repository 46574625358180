import { is } from '@babel/types';
import axios from 'axios';
import FormData from 'form-data';

type formFields = {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    mobileCountryCode: string;
    requesttype: string;
    projectOfInterest?: string;
    city: string;
};

export const formRequest = async (formFields: formFields) => {

    return new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append('post_id', '3096');
        formData.append('form_id', '3d89c1d');
        formData.append('referer_title', formFields.requesttype === 'register' ? 'Register Your Interest' : 'Callback');
        formData.append('queried_id', '3096');
        formData.append('form_fields[firstName]', formFields.firstName);
        formData.append('form_fields[lastName]', formFields.lastName);
        formData.append('form_fields[email]', formFields.email);
        formData.append('form_fields[mobileNumber]', formFields.mobileNumber);
        formData.append('form_fields[mobileCountryCode]', formFields.mobileCountryCode);
        formData.append('form_fields[requesttype]', formFields.requesttype);
        formData.append('form_fields[projectOfInterest]', formFields.requesttype === 'register' ? formFields.projectOfInterest : null);
        formData.append('form_fields[city]', formFields.city);
        formData.append('action', 'elementor_pro_forms_send_form');
        formData.append('referrer', 'https://virtualvisions.studio/ledar-crm/register-your-interest/');

        // // Use Axios to send a POST request
        axios.post('https://virtualvisions.studio/ledar-crm/wp-admin/admin-ajax.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            //@ts-ignore
        }).then((response) => {
                resolve(response);
            })
        //@ts-ignore
        .catch(error => {
            reject(error);
        });
    })
};
