// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationPinText_locationName__xjusX {
  font-family: var(--font-medium), sans-serif;
  font-size: calc(6px + (64 - 6) * (1.2px - var(--scale)) / (1.2 - 0.195));
  text-align: left;
  color: white;
  align-items: center;
  display: none;
  padding: 0px 42px;
  border-radius: 64px;
  z-index: 6;
  pointer-events: none;
  height: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));
  position: absolute;
}

.LocationPinText_hovered__1fNrl {
  display: flex;
  pointer-events: auto;
}

.LocationPinText_active__iORGa {
  border-radius: 64px 64px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 0;
}

.LocationPinText_active__iORGa > span {
  text-align: left;
  padding-top: 0.4em;
  font-weight: bold;
}

.LocationPinText_localisationData__uX\\+LV {
  width: calc(100% + 84px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  pointer-events: auto;
  border-radius: 0 0 64px 64px;
  font-size: calc(6px + (64 - 6) * (1.2px - var(--scale)) / (1.2 - 0.195));
  font-family: var(--font-medium), sans-serif;
  text-align: left;
  row-gap: 0.4em;
  margin-top: 0.4em;
  padding-bottom: 0.4em;
  margin-left: -42px;
}

.LocationPinText_localisationData__uX\\+LV span {
  padding-left: 42px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/LocationPin/LocationPinText.module.css"],"names":[],"mappings":"AAAA;EACE,2CAA2C;EAC3C,wEAAwE;EACxE,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,UAAU;EACV,oBAAoB;EACpB,wEAAwE;EACxE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,sBAAsB;EACtB,2BAA2B;EAC3B,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,oBAAoB;EACpB,4BAA4B;EAC5B,wEAAwE;EACxE,2CAA2C;EAC3C,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".locationName {\n  font-family: var(--font-medium), sans-serif;\n  font-size: calc(6px + (64 - 6) * (1.2px - var(--scale)) / (1.2 - 0.195));\n  text-align: left;\n  color: white;\n  align-items: center;\n  display: none;\n  padding: 0px 42px;\n  border-radius: 64px;\n  z-index: 6;\n  pointer-events: none;\n  height: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));\n  position: absolute;\n}\n\n.hovered {\n  display: flex;\n  pointer-events: auto;\n}\n\n.active {\n  border-radius: 64px 64px 0 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: flex-start;\n  row-gap: 0;\n}\n\n.active > span {\n  text-align: left;\n  padding-top: 0.4em;\n  font-weight: bold;\n}\n\n.localisationData {\n  width: calc(100% + 84px);\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  pointer-events: auto;\n  border-radius: 0 0 64px 64px;\n  font-size: calc(6px + (64 - 6) * (1.2px - var(--scale)) / (1.2 - 0.195));\n  font-family: var(--font-medium), sans-serif;\n  text-align: left;\n  row-gap: 0.4em;\n  margin-top: 0.4em;\n  padding-bottom: 0.4em;\n  margin-left: -42px;\n}\n\n.localisationData span {\n  padding-left: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"locationName": `LocationPinText_locationName__xjusX`,
	"hovered": `LocationPinText_hovered__1fNrl`,
	"active": `LocationPinText_active__iORGa`,
	"localisationData": `LocationPinText_localisationData__uX+LV`
};
export default ___CSS_LOADER_EXPORT___;
