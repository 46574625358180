// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileFilterServices_listContainer__LyqnF {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 8px;
}

.MobileFilterServices_listContainer__LyqnF::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.MobileFilterServices_list__ujV-e {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}

.MobileFilterServices_listItem__41uW\\+ {
  flex: 0 0 auto;
  margin-right: 16px; /* Spacing between items */
  cursor: pointer;
  background-color: #00000080;
  border-radius: 8px;
  padding: 8px;
}

.MobileFilterServices_closeButton__J86Co {
  background-color: #ffffffd6;
  border: none;
  border-radius: 12px;
  height: 48px;
  width: 48px;
  padding: 0px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MobileFilterServices/MobileFilterServices.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,wBAAwB,EAAE,+BAA+B;EACzD,qBAAqB,EAAE,YAAY;EACnC,kBAAkB;AACpB;;AAEA;EACE,aAAa,EAAE,8BAA8B;AAC/C;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;EACT,aAAa;EACb,qBAAqB;EACrB,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,kBAAkB,EAAE,0BAA0B;EAC9C,eAAe;EACf,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".listContainer {\n  overflow-x: scroll;\n  white-space: nowrap;\n  -ms-overflow-style: none; /* Internet Explorer and Edge */\n  scrollbar-width: none; /* Firefox */\n  margin-bottom: 8px;\n}\n\n.listContainer::-webkit-scrollbar {\n  display: none; /* Chrome, Safari, and Opera */\n}\n\n.list {\n  list-style: none;\n  padding: 0;\n  margin: 0;\n  display: flex;\n  align-items: flex-end;\n  gap: 4px;\n}\n\n.listItem {\n  flex: 0 0 auto;\n  margin-right: 16px; /* Spacing between items */\n  cursor: pointer;\n  background-color: #00000080;\n  border-radius: 8px;\n  padding: 8px;\n}\n\n.closeButton {\n  background-color: #ffffffd6;\n  border: none;\n  border-radius: 12px;\n  height: 48px;\n  width: 48px;\n  padding: 0px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listContainer": `MobileFilterServices_listContainer__LyqnF`,
	"list": `MobileFilterServices_list__ujV-e`,
	"listItem": `MobileFilterServices_listItem__41uW+`,
	"closeButton": `MobileFilterServices_closeButton__J86Co`
};
export default ___CSS_LOADER_EXPORT___;
