// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/backgrounds/jeddah-city.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CityPage_backgroundImage__aWvYM {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: relative;
  width: 10039px;
  height: 5647px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: all 0.3s ease;
  position: relative;
}

.CityPage_overlay__XKjNn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  transition: opacity 0.3s;
  pointer-events: none;
}

.CityPage_container__-CqEd {
  height: 100vw;
  height: 100svh;
  position: relative;
  display: flex;
  overflow: hidden;
}

@supports not (height: 100svh) {
  .CityPage_container__-CqEd {
    height: 100vh;
  }
}

.CityPage_radius__Sdc2i {
  position: absolute;
  top: 23.7%;
  left: 25.6%;
  z-index: 5;
}

.CityPage_radius__Sdc2i svg {
  width: 7000px;
  height: 7000px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CityPage/CityPage.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAyE;EACzE,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,4BAA4B;EAC5B,wBAAwB;EACxB,2BAA2B;EAC3B,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,uBAAuB;EACvB,wBAAwB;EACxB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,cAAc;AAChB","sourcesContent":[".backgroundImage {\n  background-image: url(/public/assets/images/backgrounds/jeddah-city.jpeg);\n  position: relative;\n  width: 10039px;\n  height: 5647px;\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  transition: all 0.3s ease;\n  position: relative;\n}\n\n.overlay {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: black;\n  transition: opacity 0.3s;\n  pointer-events: none;\n}\n\n.container {\n  height: 100vw;\n  height: 100svh;\n  position: relative;\n  display: flex;\n  overflow: hidden;\n}\n\n@supports not (height: 100svh) {\n  .container {\n    height: 100vh;\n  }\n}\n\n.radius {\n  position: absolute;\n  top: 23.7%;\n  left: 25.6%;\n  z-index: 5;\n}\n\n.radius svg {\n  width: 7000px;\n  height: 7000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundImage": `CityPage_backgroundImage__aWvYM`,
	"overlay": `CityPage_overlay__XKjNn`,
	"container": `CityPage_container__-CqEd`,
	"radius": `CityPage_radius__Sdc2i`
};
export default ___CSS_LOADER_EXPORT___;
