import { useEffect, useRef } from 'react'
import {
  MuseumIcon,
  AirportIcon,
  RetailIcon,
  HealthIcon,
  EducationIcon,
  BeachIcon,
  GovernmentIcon,
  StadiumIcon,
  CivilDefenseIcon,
} from '../../../icons/icons'
import styles from './LocationPin.module.css'

export interface Position {
  mobile?: { left: string; top: string }
  tablet?: { left: string; top: string }
  desktop: { left: string; top: string }
}

interface Props {
  type: string
  name: string
  position: Position
  icon: string
  onClick: (locationName: string) => void
  clickedPosition: string | null
  onHover: (locationName: string | null) => void
  lineIcon: () => JSX.Element
  lineIconStyle: { width: string; top: string; left: string }
  reversed: boolean
}

const LocationPin: React.FC<Props> = ({
  type,
  name,
  position,
  icon,
  onClick,
  onHover,
  clickedPosition,
  lineIcon,
  lineIconStyle,
  reversed,
}) => {
  const svgRef = useRef<HTMLDivElement | null>(null)
  const style = {
    left: position.mobile?.left ?? position.desktop.left,
    top: position.mobile?.top ?? position.desktop.top,
    '@media (minWidth: 768px)': {
      left: position.tablet?.left ?? position.desktop.left,
      top: position.tablet?.top ?? position.desktop.top,
    },
    '@media (minWidth: 1024px)': {
      left: position.desktop.left,
      top: position.desktop.top,
    },
  }

  const getBackgroundColor = () => {
    switch (type) {
      case `otherAmenities`:
        return '#f89248'
      case `residential`:
        return '#ff6767'
      case `retail`:
        return '#F8D648'
      case `health`:
        return '#7abc1d'
      case `business`:
        return '#6c82f7'
      case `religious`:
        return '#3353F8'
      case `education`:
        return '#987BFA'
      default:
        return ''
    }
  }

  const renderIcon = () => {
    switch (icon) {
      case `airport`:
        return <AirportIcon />
      case `beach`:
        return <BeachIcon color={'#fff'} />
      case `retail`:
        return <RetailIcon color={'#fff'} />
      case `health`:
        return <HealthIcon color={'#fff'} />
      case `government`:
        return <GovernmentIcon color={'#fff'} />
      case `museum`:
        return <MuseumIcon color={'#fff'} />
      case `stadium`:
        return <StadiumIcon color={'#fff'} />
      case `civilDefense`:
        return <CivilDefenseIcon color={'#fff'} />
      case `education`:
        return <EducationIcon color={'#fff'} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (svgRef.current) {
      const path = svgRef.current.querySelector('svg path') as SVGPathElement
      const length: number = path?.getTotalLength()

      if (path && length) {
        path.style.strokeDasharray = length.toString()
        path.style.strokeDashoffset = (-length).toString()

        let start: number | null = null
        const duration: number = 2000

        const animate = (timestamp: number) => {
          if (!start) start = timestamp
          const progress: number = timestamp - start
          const progressPercentage: number = progress / duration
          path.style.strokeDashoffset = ((1 - progressPercentage) * ((reversed ? -1 : 1) * length)).toString()

          if (progress < duration) {
            window.requestAnimationFrame(animate)
          }
        }

        window.requestAnimationFrame(animate)
      }
    }
  }, [clickedPosition, svgRef, reversed])

  return (
    <div
      className={styles.wrapper}
      style={{
        ...style,
        filter: !clickedPosition || clickedPosition === name ? 'brightness(1)' : 'brightness(0.7)',
      }}
    >
      <div
        className={styles.iconContainer}
        style={{ backgroundColor: getBackgroundColor() }}
        onClick={e => {
          e.stopPropagation()
          onClick(name)
        }}
        onMouseEnter={() => onHover(name)}
        onMouseLeave={() => onHover(null)}
      >
        {renderIcon()}
      </div>
      {clickedPosition === name && (
        <div ref={svgRef} className={styles.svgContainer} style={lineIconStyle}>
          {lineIcon()}
        </div>
      )}
    </div>
  )
}

export default LocationPin
