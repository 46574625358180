// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoomTab_container__G3Dkq {
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 40%;
  height: 5%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2em;
}

.RoomTab_button__Z-lHq {
  background-color: #00000080;
  cursor: pointer;
  border: none;
  color: #b8b8b6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 19%;
  border-radius: 16px;
  font-size: var(--font-size-5);
  font-family: var(--font-medium), sans-serif;
  transition: background-color 0.3s ease;
}

.RoomTab_button__Z-lHq:hover {
  background-color: #000000;
  color: #fff;
}

.RoomTab_selectedButton__h51qR {
  background: linear-gradient(to right, #d27bfa, #3d55f8) !important;
  color: #fff;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 19%;
  border-radius: 16px;
  font-size: var(--font-size-5);
  font-family: var(--font-bold), sans-serif;
}

@media (max-width: 991px) {
  .RoomTab_container__G3Dkq {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/RoomTab/RoomTab.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,UAAU;EACV,UAAU;EACV,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,6BAA6B;EAC7B,2CAA2C;EAC3C,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kEAAkE;EAClE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,6BAA6B;EAC7B,yCAAyC;AAC3C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  position: absolute;\n  bottom: 5%;\n  left: 50%;\n  width: 40%;\n  height: 5%;\n  transform: translateX(-50%);\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  column-gap: 2em;\n}\n\n.button {\n  background-color: #00000080;\n  cursor: pointer;\n  border: none;\n  color: #b8b8b6;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 19%;\n  border-radius: 16px;\n  font-size: var(--font-size-5);\n  font-family: var(--font-medium), sans-serif;\n  transition: background-color 0.3s ease;\n}\n\n.button:hover {\n  background-color: #000000;\n  color: #fff;\n}\n\n.selectedButton {\n  background: linear-gradient(to right, #d27bfa, #3d55f8) !important;\n  color: #fff;\n  cursor: pointer;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 19%;\n  border-radius: 16px;\n  font-size: var(--font-size-5);\n  font-family: var(--font-bold), sans-serif;\n}\n\n@media (max-width: 991px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RoomTab_container__G3Dkq`,
	"button": `RoomTab_button__Z-lHq`,
	"selectedButton": `RoomTab_selectedButton__h51qR`
};
export default ___CSS_LOADER_EXPORT___;
