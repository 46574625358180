// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CopyrightText_container__LmSe0 {
  color: #808080;
  font-size: var(--font-size-2);
  position: absolute;
  bottom: 2%;
  font-family: var(--font-regular), sans-serif;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/CopyrightText/CopyrightText.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,6BAA6B;EAC7B,kBAAkB;EAClB,UAAU;EACV,4CAA4C;EAC5C,qBAAqB;AACvB","sourcesContent":[".container {\n  color: #808080;\n  font-size: var(--font-size-2);\n  position: absolute;\n  bottom: 2%;\n  font-family: var(--font-regular), sans-serif;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CopyrightText_container__LmSe0`
};
export default ___CSS_LOADER_EXPORT___;
