// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/backgrounds/map.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountryPage_backgroundImage__Mk3kj {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the image */
  height: calc(var(--vh, 1vh) * 100);
}

.CountryPage_container__h5RaI {
  height: 100%;
  position: relative;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CountryPage/CountryPage.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAgE;EAChE,sBAAsB,EAAE,+BAA+B;EACvD,2BAA2B,EAAE,qBAAqB;EAClD,kCAAkC;AACpC;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".backgroundImage {\n  background-image: url(/public/assets/images/backgrounds/map.png);\n  background-size: cover; /* Cover the entire container */\n  background-position: center; /* Center the image */\n  height: calc(var(--vh, 1vh) * 100);\n}\n\n.container {\n  height: 100%;\n  position: relative;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroundImage": `CountryPage_backgroundImage__Mk3kj`,
	"container": `CountryPage_container__h5RaI`
};
export default ___CSS_LOADER_EXPORT___;
