// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RangeSlider_container__FYYR7 {
  margin-top: 16px;
}

.RangeSlider_rangeButton__MxGXV {
  position: absolute;
  width: '10px';
  height: '20px';
  background-color: #d27bfa;
}

.RangeSlider_priceRangeValue__jtt\\+t {
  background-color: #000;
  color: #fff;
  font-size: var(--font-size-3);
  padding: 12px 6px;
  border-radius: 8px;
  margin-top: 8%;
  font-family: var(--font-medium), sans-serif;
  display: flex;
  align-items: center;
}

.RangeSlider_option__7pcg\\+ {
  background-color: transparent;
  color: #4d4d4d;
  border: none;
  appearance: none;
  font-family: var(--font-medium), sans-serif;
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/RangeSlider/RangeSlider.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;EACd,2CAA2C;EAC3C,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,2CAA2C;EAC3C,iBAAiB;AACnB","sourcesContent":[".container {\n  margin-top: 16px;\n}\n\n.rangeButton {\n  position: absolute;\n  width: '10px';\n  height: '20px';\n  background-color: #d27bfa;\n}\n\n.priceRangeValue {\n  background-color: #000;\n  color: #fff;\n  font-size: var(--font-size-3);\n  padding: 12px 6px;\n  border-radius: 8px;\n  margin-top: 8%;\n  font-family: var(--font-medium), sans-serif;\n  display: flex;\n  align-items: center;\n}\n\n.option {\n  background-color: transparent;\n  color: #4d4d4d;\n  border: none;\n  appearance: none;\n  font-family: var(--font-medium), sans-serif;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `RangeSlider_container__FYYR7`,
	"rangeButton": `RangeSlider_rangeButton__MxGXV`,
	"priceRangeValue": `RangeSlider_priceRangeValue__jtt+t`,
	"option": `RangeSlider_option__7pcg+`
};
export default ___CSS_LOADER_EXPORT___;
