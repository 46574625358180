import { useState } from 'react';
import { ZoomIcon, UnzoomIcon, FrameIcon } from '../../../icons/icons';
import styles from './ZoomToolbarButton.module.css';

interface Props {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

const ZoomToolbarButton: React.FC<Props> = ({ name, isSelected, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const renderIcon = () => {
    switch (name) {
      case 'unzoom':
        return <UnzoomIcon color={isSelected ? '#000' : '#fff'} opacity={isHovered ? 1 : 0.65} />;
      case 'zoom':
        return <ZoomIcon color={isSelected ? '#000' : '#fff'} opacity={isHovered ? 1 : 0.65} />;
      case 'frame':
        return <FrameIcon color={isSelected ? '#000' : '#fff'} opacity={isHovered ? 1 : 0.65} />;
      default:
        return null;
    }
  };

  const buttonClass = isSelected ? `${styles.container} ${styles.selected}` : styles.container;

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {renderIcon()}
    </button>
  );
};

export default ZoomToolbarButton;
