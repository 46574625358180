import React from 'react'

export const MaritimeTrainingCenterLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="197.812" height="94.929" viewBox="0 0 197.812 94.929">
    <path
      id="Tracé_41949"
      data-name="Tracé 41949"
      d="M84.111-1904.065l39.37,15.12-24.1,64.866L94.428-1805.6l-3.282,17.862-5.893,26.091-6.189,19,11.745,29.536"
      transform="matrix(-0.259, -0.966, 0.966, -0.259, 1872.378, -322.778)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const PrinceAdbualahAlFaisalStadiumLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="158.663" height="276.936" viewBox="0 0 158.663 276.936">
    <path
      id="Prince_Abdullah_AlFaisal_stadium"
      data-name="Prince Abdullah AlFaisal stadium"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-19.369-16.8-20-.464-16.718,17.8"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 246.583, 325.62)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const IbnSinaNationalCollegeForMedicalStudiesLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="235.648" height="278.147" viewBox="0 0 235.648 278.147">
    <path
      id="Ibn_Sina_National_College_for_Medical_Studies"
      data-name="Ibn Sina National College for Medical Studies"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-33.957,3.87,14.425,70.172-1.83,12.543"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 329.173, 326.832)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const SouthMallLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="290.97" height="292.971" viewBox="0 0 290.97 292.971">
    <path
      id="South_Mall"
      data-name="South Mall"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-33.957,3.87,14.425,70.172L-242.043,388.9l.279,10.942"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 384.494, 341.655)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const KhuzamPalaceLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="308.741" height="345.171" viewBox="0 0 308.741 345.171">
    <path
      id="Khuzam_Palace"
      data-name="Khuzam Palace"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-33.957,3.87,14.425,70.172-9.718,41.288-43.8-17.84L-298.51,403.82"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 388.571, 393.855)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const NewJeddahCornicheLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="785.786" height="826.951" viewBox="0 0 785.786 826.951">
    <path
      id="New_Jeddah_Corniche"
      data-name="New Jeddah Corniche"
      d="M-650.941,592.535l23.744-6.362,53.716-34.164L-540.913,551l78.353-15.217,21.44-31.12-14.057-37.585-.932-42.273,34.551-25.271,94.482-67.623,141.048-66.235-6.982-43.112,71.257-19.093,43.172-77.441,37.857-17.369L2.9,35.229l6.333-6.641L-1.575-11.167l36.729-7.477L32.214-35.618"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 582.674, 782.62)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const KingAbdulazizInternationalAirportLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="754.795" height="1034.915" viewBox="0 0 754.795 1034.915">
    <path
      id="King_Abdulaziz_International_Airport"
      data-name="King Abdulaziz International Airport"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-19.369-16.8-22.076-13.58-39.407-5.175-19.173-2.574-32.109-19.832-81.837,50.364-43.778,59.007L-517,355.593l-98.208,59.089L-732.63,469.806-927.248,599.057l-7.1,18.447,35.714,50.4-25.372,16.3,2.947,11"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 670.016, 1083.599)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const MonumentLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="240.278" height="134.651" viewBox="0 0 240.278 134.651">
    <path
      id="Monument"
      d="M-6.9,190.975l-10.717-1.523L-24.549,155l-6.723-40.056-9.452-6.276L2.9,35.229l6.333-6.641L-1.575-11.167l36.729-7.477L32.214-35.618"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 194.82, 90.321)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const EffatUniversityLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="274.235" height="354.642" viewBox="0 0 274.235 354.642">
    <path
      id="Effat_University"
      data-name="Effat University"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-19.369-16.8-22.076-13.58-39.407-5.175-27.11,142.273"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 348.663, 403.326)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const KingAbdulAzizUniversityLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="250.443" height="467.195" viewBox="0 0 250.443 467.195">
    <path
      id="King_Abdul_Aziz_University"
      data-name="King Abdul Aziz University"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-19.369-16.8-22.076-13.58-39.407-5.175-19.173-2.574-32.109-19.832-81.837,50.364L-451.741,283.8l14.157,18.185"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 290.539, 515.879)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const CollegeOfTelecomElectronicsLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="353.417" height="318.534" viewBox="0 0 353.417 318.534">
    <path
      id="College_of_Telecom_Electronics"
      data-name="College of Telecom &amp; Electronics"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-33.957,3.87,14.425,70.172L-242.043,388.9l12.377,46.19-19.138,5.128-6.653,21.363"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 444.393, 367.218)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const KingAbdulazizHospitalLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="221.93" height="236.432" viewBox="0 0 221.93 236.432">
    <path
      id="King_Abdulaziz_Hospital"
      data-name="King Abdulaziz Hospital"
      d="M-151.45,143l44.606-71.393,10.339,5.923,8.573-17.967,23.111-39.7,9.675,5.63L-34.32-11.163,44.824-32.369"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 150.313, 184.258)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const CivilDefenseSouthernHousingLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="190.892" height="217.672" viewBox="0 0 190.892 217.672">
    <path
      id="Civil_Defense_Southern_housing"
      data-name="Civil Defense Southern housing"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-84.372,33.972L-93.805,69.854l-84.064-16.711-1.1,27.8-13.436-1.069"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 191.236, 237.639)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)

export const MinistryOfCommerceLine = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="335.375" height="397.459" viewBox="0 0 335.375 397.459">
    <path
      id="Ministry_of_Commerce"
      data-name="Ministry of Commerce"
      d="M-6.9,190.975l-10.717-1.523-6.749-25.189-85.128,36.482-101.916,55.119-33.957,3.87,14.425,70.172-9.718,41.288L-286.4,353.147l-62.357-7.834,3.665,53.865.752,18.951"
      transform="matrix(-0.259, 0.966, -0.966, -0.259, 402.171, 446.143)"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
)
