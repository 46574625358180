import React, { CSSProperties } from 'react'
import { CompassIcon, WarningIcon } from '../../../icons/icons'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styles from './Compass.module.css'

export interface Position {
  mobile: {
    en: CSSProperties
    ar: CSSProperties
  }
  desktop: {
    en: CSSProperties
    ar: CSSProperties
  }
}

interface CompassProps {
  position: Position
}

const Compass: React.FC<CompassProps> = ({ position }) => {
  const { i18n, t } = useTranslation()
  const currentLang = i18n.language

  const containerStyle: CSSProperties = {
    ...(isMobile ? position.mobile[currentLang as 'en' | 'ar'] : position.desktop[currentLang as 'en' | 'ar']),
  }

  return (
    <>
      <div className={styles.container} style={{ ...containerStyle }}>
        <CompassIcon />
      </div>
    </>
  )
}

export default Compass
