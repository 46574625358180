import { CSSProperties } from 'react';
import styles from './CopyrightText.module.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const CopyrightText: React.FC = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLanguage = i18n.language;

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === 'ar' ? '2%' : 'unset',
    left: currentLanguage === 'ar' ? 'unset' : '2%',
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  if (location.pathname === '/') {
    return null;
  }
  return (
    <a
      href="http://www.virtualvisions.studio/"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.container}
      style={containerStyle}
    >
      {t(`copyright`)}
    </a>
  );
};

export default CopyrightText;
