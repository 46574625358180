import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Property } from '../types/property';
import { Status } from '../types/status';

export const propertiesApi = createApi({
  reducerPath: 'propertiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://dkkkow0.etherial.cloud',
  }),

  endpoints: (builder) => ({
    getProperties: builder.query<Property[], string>({
      query: (project) => `${project}/properties`,
      transformResponse: async (response: { data: Property[] }) => {
        return response.data;
      },
    }),
    getProperty: builder.query<Property, { project: string; id: number }>({
      query: ({ project, id }) => `${project}/properties/${id}`,
      transformResponse: async (response: { data: Property }) => {
        return response.data;
      },
    }),
    getStatus: builder.query({
      query: () => `status`,
      transformResponse: async (response: { data: Status[] }) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetPropertiesQuery, useGetStatusQuery, useLazyGetPropertyQuery } = propertiesApi;
