// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZoomToolbarButton_container__vi\\+4e {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 80%;
  aspect-ratio: 1;
  border-radius: 12px;
}

.ZoomToolbarButton_container__vi\\+4e:not(.ZoomToolbarButton_selected__-eBG0):hover {
  background-color: #100f0d;
}

.ZoomToolbarButton_selected__-eBG0 {
  background-color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/ZoomToolbarButton/ZoomToolbarButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,6BAA6B;EAC7B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,sCAAsC;EACtC,WAAW;EACX,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".container {\n  border: none;\n  background-color: transparent;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 4px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  height: 80%;\n  aspect-ratio: 1;\n  border-radius: 12px;\n}\n\n.container:not(.selected):hover {\n  background-color: #100f0d;\n}\n\n.selected {\n  background-color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZoomToolbarButton_container__vi+4e`,
	"selected": `ZoomToolbarButton_selected__-eBG0`
};
export default ___CSS_LOADER_EXPORT___;
