import styles from './MobileTabletShareCard.module.css'
import { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PageContext } from '../../../contexts'
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  WhatsappIcon,
  TelegramIcon,
  LinkLockIcon,
  CloseIcon,
} from '../../../icons/icons'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

const MobileTabletShareCard: React.FC = () => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { setActiveButton } = useContext(PageContext)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [borderColor, setBorderColor] = useState('#3e3d44')
  const [showTooltip, setShowTooltip] = useState(false)

  const copyToClipboard = () => {
    if (inputRef.current !== null) {
      const inputValue = inputRef.current.value

      // Check if Clipboard API is available
      if (navigator.clipboard) {
        navigator.clipboard.writeText(inputValue).then(
          () => {
            setBorderColor('#d27bfa')
          },
          err => {
            console.error('Could not copy text: ', err)
          }
        )
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea')
        textarea.value = inputValue
        textarea.style.position = 'fixed'
        textarea.style.opacity = '0'
        document.body.appendChild(textarea)
        textarea.select()
        try {
          document.execCommand('copy')
          setBorderColor('#d27bfa')
        } catch (err) {
          console.error('Fallback: Could not copy text', err)
        }
        document.body.removeChild(textarea)
      }
    }
    // Show tooltip
    setShowTooltip(true)

    // Hide tooltip after 2 seconds
    // setTimeout(() => {
    //   setShowTooltip(false);
    // }, 2000);
  }

  useEffect(() => {
    const resetBorderColor = () => setBorderColor('#3e3d44') // Reset to default color
    document.addEventListener('mousedown', resetBorderColor)
    return () => {
      document.removeEventListener('mousedown', resetBorderColor)
    }
  }, [])

  const handleClose = useCallback(() => {
    setActiveButton(null) // Reset the activeButton state
  }, [setActiveButton])

  // Effect to add and remove the event listener
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setTimeout(() => {
          handleClose()
        }, 100)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClose])

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  }

  const tooltipStyle: CSSProperties & { [key: string]: any } = {
    left: currentLanguage === 'ar' ? '16%' : 'unset',
    right: currentLanguage === 'ar' ? 'unset' : '-4%',
  }

  if (!isMobile) {
    return null
  }
  return (
    <div className={styles.container} ref={containerRef} style={containerStyle}>
      <div className={styles.header}>
        <div>{t(`nav.share`)}</div>
        <button className={styles.closeButton} onClick={handleClose}>
          <CloseIcon color="#fff" />
        </button>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.columnWrapper}>
          <div className={styles.wrapperTitle}>{t(`share.shareLink`)}</div>
          <div className={styles.shareButtonWrapper}>
            <TwitterShareButton
              title="Ledar Project Masterplan, Discover Ledar Project Interactive Map & 3D VR"
              url={window.location.href}
            >
              <button className={styles.shareButton}>
                <TwitterIcon />
              </button>
            </TwitterShareButton>
            <FacebookShareButton
              title="Ledar Project Masterplan, Discover Ledar Project Interactive Map & 3D VR"
              url={window.location.href}
            >
              <button className={styles.shareButton}>
                <FacebookIcon />
              </button>
            </FacebookShareButton>
            {/* <button className={styles.shareButton}>
              <InstagramIcon />
            </button> */}
            <WhatsappShareButton
              title="Ledar Project Masterplan, Discover Ledar Project Interactive Map & 3D VR"
              url={window.location.href}
            >
              <button className={styles.shareButton}>
                <WhatsappIcon />
              </button>
            </WhatsappShareButton>
            <TelegramShareButton
              title="Ledar Project Masterplan, Discover Ledar Project Interactive Map & 3D VR"
              url={window.location.href}
            >
              <button className={styles.shareButton}>
                <TelegramIcon />
              </button>
            </TelegramShareButton>
          </div>
        </div>
        <div className={styles.columnWrapper}>
          <div className={styles.wrapperTitle}>{t(`share.copyLink`)}</div>
          <div className={styles.inputWrapper} style={{ borderColor: borderColor }}>
            <div className={styles.iconLinkWrapper}>
              <LinkLockIcon />
              <input type="text" value={window.location.href} ref={inputRef} readOnly className={styles.hyperlink} />
            </div>
            {showTooltip && (
              <div className={styles.tooltip} style={tooltipStyle}>
                {t(`share.copied`)}
              </div>
            )}
            <button className={styles.copyButton} onClick={copyToClipboard}>
              {t(`share.copy`)}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileTabletShareCard
