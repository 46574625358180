import React, { CSSProperties, useEffect } from 'react'
import { CompassIcon, WarningIcon } from '../../../icons/icons'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styles from './Disclamer.module.css'
//@ts-ignore
import Modal from 'react-modal'

export interface Position {
  mobile: {
    en: CSSProperties
    ar: CSSProperties
  }
  desktop: {
    en: CSSProperties
    ar: CSSProperties
  }
}

interface DisclamerProps {
  position: Position
}

const Disclamer: React.FC<DisclamerProps> = ({ position }) => {
  const { i18n, t } = useTranslation()
  const currentLang = i18n.language

  const containerStyle: CSSProperties = {
    ...(isMobile ? position.mobile[currentLang as 'en' | 'ar'] : position.desktop[currentLang as 'en' | 'ar']),
  }

  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  useEffect(() => {
    if (window.location.pathname === '/masterplan') {
      setTimeout(() => {
        openModal()
      }, 1000)
    }
  }, [])

  return (
    <>
      <div
        className={styles.container}
        style={{ ...containerStyle, opacity: 0.5, zIndex: 9, cursor: 'pointer' }}
        onClick={() => openModal()}
      >
        <WarningIcon />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            backgroundColor: '#1f1f1f',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '500px',
            color: 'white',
            fontFamily: 'regular',
            borderRadius: '15px',
            border: 0,
            opacity: 0.85,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        contentLabel="Example Modal"
      >
        <button
          onClick={closeModal}
          style={{ float: 'right', cursor: 'pointer', border: 0, background: 'none', color: 'white' }}
        >
          X
        </button>
        <h2 style={{ textAlign: 'center' }}>
          <WarningIcon />
        </h2>
        <h2 style={{ textAlign: 'center' }}>{t(`fixed.disclamer`)}</h2>
        <p>{t(`fixed.disclamer_text`)}</p>
      </Modal>
    </>
  )
}

export default Disclamer
