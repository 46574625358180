// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Amenities_container__DfB31 {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 1%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Amenities/Amenities.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,OAAO;AACT","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n  gap: 1%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Amenities_container__DfB31`
};
export default ___CSS_LOADER_EXPORT___;
