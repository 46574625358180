import { useState } from 'react'
import { ArrowleftIcon, ArrowrightIcon, CloseAmenitiesIcon } from '../../../icons/icons'
import styles from './GalleryModal.module.css'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
    show: boolean
    onClose: () => void
}

const GalleryModal: React.FC<Props> = ({ show, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const images = [
        '/assets/images/carrousel-image1.jpeg',
        '/assets/images/carrousel-image2.jpg',
        '/assets/images/carrousel-image3.jpg',
        '/assets/images/carrousel-image4.jpg',
        '/assets/images/carrousel-image5.jpg',
        '/assets/images/carrousel-image6.jpg',
        '/assets/images/carrousel-image7.jpg',
    ]

    const modalVariants = {
        hidden: { opacity: 0, scale: 0.9 },
        visible: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.9 },
    }

    const progressWidth = 100 / images.length

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length)
    }

    if (!show) return null
    return (
        <motion.div  initial='hidden' animate='visible' exit='exit' variants={modalVariants} transition={{ duration: 0.3 }}>
            {/* <div className={styles.galleryInfo}>
                <div className={styles.galleryName}>Collection Name 02</div>
                <div className={styles.galleryContent}>28 photos</div>
            </div> */}
            {/* <button className={styles.closeButton} onClick={onClose}>
                <CloseAmenitiesIcon color='#fff' />
            </button> */}
            <div className={styles.carouselContainer}>
                <div className={styles.carousel}>
                    <AnimatePresence mode='wait'>
                        <motion.div key={currentIndex} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
                            <img
                                className={`${styles.carouselImage} ${styles.InnactiveImage}`}
                                src={images[(currentIndex - 1 + images.length) % images.length]}
                                alt='Previous'
                            />
                        </motion.div>
                    </AnimatePresence>
                    <div style={{ position: 'relative' }}>
                        <button className={styles.navButton} onClick={handlePrev}>
                            <ArrowleftIcon />
                        </button>
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={currentIndex}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}>
                                <img className={styles.carouselImage} src={images[currentIndex]} alt='Current' />
                            </motion.div>
                        </AnimatePresence>
                        <button className={styles.navButton} onClick={handleNext}>
                            <ArrowrightIcon />
                        </button>
                    </div>
                    <AnimatePresence mode='wait'>
                        <motion.div key={currentIndex} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
                            <img className={`${styles.carouselImage} ${styles.InnactiveImage}`} src={images[(currentIndex + 1) % images.length]} alt='Next' />
                        </motion.div>
                    </AnimatePresence>
                </div>
                <div className={styles.pagination}>
                    <div className={styles.progressBar}>
                        <div
                            className={styles.progress}
                            style={{
                                width: `${progressWidth}%`,
                                left: `${(currentIndex / (images.length - 1)) * (100 - progressWidth)}%`,
                            }}></div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default GalleryModal
