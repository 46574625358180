import React from 'react';
import styles from './BackButton.module.css';
import { BackIcon, InvertedBackIcon } from '../../../icons/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const BackButton: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const ArrowIcon = currentLanguage === 'ar' ? InvertedBackIcon : BackIcon;
  return (
    <button className={styles.container} onClick={() => navigate(-1)}>
      <ArrowIcon />
      <span className={styles.text}>{t(`button.back`)}</span>
    </button>
  );
};

export default BackButton;
