import { CSSProperties, useEffect, useRef, useState } from 'react';
import { CloseAmenitiesIcon, PlayerIcon } from '../../../icons/icons';
import styles from './VideoModal.module.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  onClose: () => void;
  initialVideoSrc: string;
}

const VideoModal: React.FC<Props> = ({ show, onClose, initialVideoSrc }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [currentVideoSrc, setCurrentVideoSrc] = useState<string>(initialVideoSrc);
  const innerWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentVideoSrc(initialVideoSrc);
  }, [initialVideoSrc]);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (innerWrapperRef.current) {
        innerWrapperRef.current.scrollLeft += e.deltaY;
      }
    };

    const element = innerWrapperRef.current;
    if (element) {
      element.addEventListener('wheel', handleWheel);
    }

    return () => {
      if (element) {
        element.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  useEffect(() => {
    if (show) {
      document.body.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
    }

    // Cleanup on component unmount
    return () => {
      document.body.classList.remove('noScroll');
    };
  }, [show]);

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.9 },
  };

  const directionStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  return (
    <motion.div
      className={styles.fullScreenModal}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={modalVariants}
      transition={{ duration: 0.3 }}
    >
      <button className={styles.closeButton} onClick={onClose}>
        <CloseAmenitiesIcon color="#fff" />
      </button>
      <video width="80%" height="80%" controls key={currentVideoSrc} className={styles.videoPlayer}>
        <source src={currentVideoSrc} type="video/mp4" />
      </video>
      <div className={styles.videosWrapper}>
        <div className={styles.innerWrapper} ref={innerWrapperRef} style={directionStyle}>
          <div className={styles.videoWrapper}>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail1.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample1.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail2.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample2.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail1.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample1.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail2.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample2.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail1.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample1.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail2.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample2.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
          <div>
            <div className={styles.thumbnailWrapper}>
              <img
                className={styles.video}
                src="/assets/images/videoThumbnail1.png"
                onClick={() => setCurrentVideoSrc('/assets/videos/sample1.mp4')}
                alt="video thumbnail"
              />
              <div className={styles.thumbnailIconWrapper}>
                <PlayerIcon color="#111" />
              </div>
            </div>
            <div className={styles.videoTitle}>{t(`videoPage.welcome`)}</div>
            <div className={styles.videoDescription}>{t(`videoPage.theFutur`)}</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default VideoModal;
