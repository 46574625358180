// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalTaletMobileFilters_container__lbZkl {
  position: absolute;
  bottom: 10%;
}

.HorizontalTaletMobileFilters_filter__hDi8v {
  border: none;
  background-color: #00000080;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin: 0;
}

.HorizontalTaletMobileFilters_filterRow__iq65p {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,kBAAkB;AACpB","sourcesContent":[".container {\n  position: absolute;\n  bottom: 10%;\n}\n\n.filter {\n  border: none;\n  background-color: #00000080;\n  border-radius: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 48px;\n  width: 48px;\n  margin: 0;\n}\n\n.filterRow {\n  display: flex;\n  align-items: center;\n  margin: 0;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HorizontalTaletMobileFilters_container__lbZkl`,
	"filter": `HorizontalTaletMobileFilters_filter__hDi8v`,
	"filterRow": `HorizontalTaletMobileFilters_filterRow__iq65p`
};
export default ___CSS_LOADER_EXPORT___;
