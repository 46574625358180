import { useState, useEffect } from 'react';
import { DocumentsIcon, GalleryIcon, HomeArrowRightIcon, LedarLogoIconSmall, VideoIcon } from '../../icons/icons';
import styles from './HomePage.module.css';
import { useTranslation } from 'react-i18next';
import DocumentPage from '../DocumentPage/DocumentPage';
import GalleryPage from '../GalleryPage/GalleryPage';
import VideoPage from '../VideoPage/VideoPage';
import Layout from '../../components/Layout/Layout';
import { motion, AnimatePresence } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import MobileTabletHomePage from './MobileTablet/MobileTabletHomePage';
import { Link } from 'react-router-dom';
import stylesG from './../GlobePage/GlobePage.module.css';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('home');
  const [showShadow, setShowShadow] = useState({
    video: false,
    gallery: false,
    documents: false,
    arrow_video: false,
    arrow_gallery: false,
    arrow_documents: false,
  });

  useEffect(() => {
    const videoElement = document.querySelector(`.${styles.video}`) as HTMLVideoElement;
    const tabletBreakpoint = 768;

    if (videoElement && window.innerWidth < tabletBreakpoint) {
      videoElement.pause();
    }
  }, []);

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener('resize', setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener('resize', setBodyHeight);
    };
  }, []);

  // Function to handle mouse enter
  const handleMouseEnter = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: true,
      [`arrow_${buttonName}`]: true,
    });
  };

  // Function to handle mouse leave
  const handleMouseLeave = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: false,
      [`arrow_${buttonName}`]: false,
    });
  };

  const handlePageChange = (newPage: string) => {
    setActivePage(newPage);
  };

  const goBackToHome = () => {
    setActivePage('home');
  };

  const handleVideoError = () => {
    const videoContainer = document.querySelector(`.${styles.videoBackground}`);
    if (videoContainer) {
      videoContainer.classList.add(styles.videoFail);
    }
  };

  if (isMobile) {
    return <MobileTabletHomePage />;
  }

  return (
    <div className={stylesG.videoBackground}>
      {/* Video for larger screens */}
      <video autoPlay loop muted className={stylesG.video} onError={handleVideoError}>
        <source src="/assets/videos/bgVideo.mp4" type="video/mp4" />
      </video>

      {/* Fallback image for desktop */}
      <div className={stylesG.desktopFallback}></div>

      {/* Image for mobile */}
      <div className={stylesG.mobileBackground}></div>

      <div className={styles.container}>
        <AnimatePresence mode="wait">
          {activePage === 'home' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.homeContainer}
            >
              <div className={styles.homeLogo}>
                <LedarLogoIconSmall color="#fff" />
              </div>
              <div className={styles.textWrapper}>
                <div className={styles.topBorder} />
                <div className={styles.title}>
                  {t(`homePage.title`)} {t(`homePage.project`)}
                </div>
                <div className={styles.subTitle}>{t(`homePage.subTitle`)}</div>
                {/* <button className={styles.exploreButton} onClick={handleClick}>
                    {t(`homePage.explore`)}
                </button> */}
                <Link to="/city" className={styles.exploreButton}>
                  {t(`homePage.explore`)}
                </Link>
              </div>
              <div className={styles.menuWrapper}>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('video')}
                  onMouseLeave={() => handleMouseLeave('video')}
                  onClick={() => setActivePage('video')}
                >
                  <div className={styles.menuItemWrapper}>
                    <VideoIcon showShadow={showShadow.video} />
                    <div className={styles.menuItem}>{t(`homePage.videos`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_video} />
                </button>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('gallery')}
                  onMouseLeave={() => handleMouseLeave('gallery')}
                  onClick={() => setActivePage('gallery')}
                >
                  <div className={styles.menuItemWrapper}>
                    <GalleryIcon showShadow={showShadow.gallery} />
                    <div className={styles.menuItem}>{t(`homePage.gallery`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_gallery} />
                </button>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('documents')}
                  onMouseLeave={() => handleMouseLeave('documents')}
                  onClick={() => setActivePage('documents')}
                >
                  <div className={styles.menuItemWrapper}>
                    <DocumentsIcon showShadow={showShadow.documents} />
                    <div className={styles.menuItem}>{t(`homePage.documents`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_documents} />
                </button>
              </div>
            </motion.div>
          )}
          {activePage === 'video' && (
            <Layout activeButton="video" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <VideoPage />
            </Layout>
          )}
          {activePage === 'gallery' && (
            <Layout activeButton="gallery" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <GalleryPage />
            </Layout>
          )}
          {activePage === 'documents' && (
            <Layout activeButton="documents" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <DocumentPage />
            </Layout>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HomePage;
