// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VRModeButton_container__v5rJm {
  background-color: #00000080;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.VRModeButton_container__v5rJm:hover {
  background-color: #000000;
}

.VRModeButton_text__BFKkt {
  font-size: var(--font-size-4);
  font-family: var(--font-medium), sans-serif;
  color: #fff;
  margin-left: 8px;
  white-space: nowrap;
}

@media (min-width: 768px) and (max-width: 991px) {
  .VRModeButton_container__v5rJm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .VRModeButton_container__v5rJm {
    display: none;
  }
}

@media (max-width: 767px) {
  .VRModeButton_container__v5rJm {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/VRModeButton/VRModeButton.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,2CAA2C;EAC3C,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  background-color: #00000080;\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  box-sizing: border-box;\n  padding: 0px 16px;\n  cursor: pointer;\n  border: none;\n  transition: background-color 0.3s ease;\n}\n\n.container:hover {\n  background-color: #000000;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  font-family: var(--font-medium), sans-serif;\n  color: #fff;\n  margin-left: 8px;\n  white-space: nowrap;\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .container {\n    display: none;\n  }\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .container {\n    display: none;\n  }\n}\n\n@media (max-width: 767px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `VRModeButton_container__v5rJm`,
	"text": `VRModeButton_text__BFKkt`
};
export default ___CSS_LOADER_EXPORT___;
