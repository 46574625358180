import { useTranslation } from 'react-i18next'
import styles from './MobileTabletVideoPage.module.css'
import { isMobile } from 'react-device-detect'
import { CSSProperties, useState } from 'react'
import { PlayerIcon } from '../../../icons/icons'
import VideoModal from '../../../components/Modal/VideoModal/VideoModal'
import { AnimatePresence } from 'framer-motion'

const MobileTabletVideoPage: React.FC = () => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    const [showModal, setShowModal] = useState(false)
    const [videoSrc, setVideoSrc] = useState<string>('')

    const handleVideoClick = (src: string) => {
        setVideoSrc(src)
        setShowModal(true)
    }

    const closeModal = () => {
        setShowModal(false)
    }

    if (!isMobile) {
        return null
    }

    const directionStyle: CSSProperties = {
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
    }
    return (
        <div className={styles.container}>
            <span className={styles.title}>{t(`videoPage.title`)}</span>
            <div className={styles.featuredVideosWrapper} style={directionStyle}>
                <video src="/assets/videos/DarAlsadan-MainVideo.mp4" controls style={{height: "55vh", width: "100%"}} playsInline autoPlay muted ></video>

            </div>

            <AnimatePresence>{showModal && <VideoModal show={showModal} onClose={closeModal} initialVideoSrc={videoSrc} />}</AnimatePresence>
        </div>
    )
}

export default MobileTabletVideoPage
