import { useContext, useEffect, useState } from 'react';
import styles from './HorizontalTabletRoomTab.module.css';
import { PageContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { CameraFloorType } from '../../../pages/HousePlanPage/HousePlanPage';

const floorCameras: CameraFloorType[] = [
  {
    type: 1,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera006' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera011' },
          { alias: 'Dining Room', url: 'Corona Camera005' },
          { alias: 'Majless', url: 'Corona Camera004' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera024' },
          { alias: 'Junior Room 1', url: 'Corona Camera019' },
          { alias: 'Junior Room 2', url: 'Corona Camera021' },
          { alias: 'Junior Room 3', url: 'Corona Camera028' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera034' },
          { alias: 'Bath Room', url: 'Corona Camera035' },
        ],
      },
    ],
  },
  {
    type: 2,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera032' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera038' },
          { alias: 'Dining Room', url: 'Corona Camera030' },
          { alias: 'Majless', url: 'Corona Camera028' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera055' },
          { alias: 'Junior Room 1', url: 'Corona Camera052' },
          { alias: 'Junior Room 2', url: 'Corona Camera048' },
          { alias: 'Junior Room 3', url: 'Corona Camera046' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera063' },
          { alias: 'Bath Room', url: 'Corona Camera064' },
        ],
      },
    ],
  },
  {
    type: 3,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera032' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera038' },
          { alias: 'Dining Room', url: 'Corona Camera030' },
          { alias: 'Majless', url: 'Corona Camera028' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera055' },
          { alias: 'Junior Room 1', url: 'Corona Camera052' },
          { alias: 'Junior Room 2', url: 'Corona Camera048' },
          { alias: 'Junior Room 3', url: 'Corona Camera046' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera063' },
          { alias: 'Bath Room', url: 'Corona Camera064' },
          { alias: 'Multipurpose Room', url: 'Corona Camera062' },
        ],
      },
    ],
  },
];

const HorizontalTabletRoomTab: React.FC = () => {
  const { t } = useTranslation();
  const { isVRMode, iframeVR, currentFloor, displayedProperty } = useContext(PageContext);
  const [selectedButton, setSelectedButton] = useState('');
  const [model, setModel] = useState<number | null>(null);
  const [cameraButtons, setCameraButtons] = useState<any[]>([]);

  useEffect(() => {
    if (displayedProperty) {
      const model = displayedProperty.property_type_id;

      setModel(model);
    }
  }, [displayedProperty]);

  useEffect(() => {
    if (model !== null && currentFloor !== null) {
      const cameras = floorCameras
        .find((camera) => camera.type === model)
        ?.floors.find((floor) => floor.number === currentFloor)?.cameras;

      if (cameras) {
        setCameraButtons(cameras);
      }
    }
  }, [model, currentFloor]);

  const handleButtonClick = (url: string) => {
    iframeVR.current?.contentWindow?.postMessage({ v1: 'LayamaTeleportCamera', v2: url }, '*');
    setSelectedButton(url);
  };

  if (!isVRMode) {
    return null;
  }

  return (
    <div className={styles.container}>
      {cameraButtons.map((camera, index) => (
        <button
          key={index}
          className={selectedButton === camera.url ? styles.selectedButton : styles.button}
          onClick={() => handleButtonClick(camera.url)}
        >
          {camera.alias}
        </button>
      ))}
    </div>
  );
};

export default HorizontalTabletRoomTab;
