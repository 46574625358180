// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationPin_wrapper__NuZgl {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1em;
  transition: all 0.3s ease;
  z-index: 1;
}

.LocationPin_iconContainer__ikvRu {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));
  height: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));
}

.LocationPin_iconContainer__ikvRu svg {
  width: calc(32px + (60 - 32) * (1.2px - var(--scale)) / (1.2 - 0.195));
  height: calc(32px + (60 - 32) * (1.2px - var(--scale)) / (1.2 - 0.195));
}

.LocationPin_svgContainer__i-2p8 {
  position: absolute;
  height: auto;
  z-index: -1;
}

.LocationPin_svgContainer__i-2p8 svg {
  width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/LocationPin/LocationPin.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,uEAAuE;EACvE,wEAAwE;AAC1E;;AAEA;EACE,sEAAsE;EACtE,uEAAuE;AACzE;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".wrapper {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 1em;\n  transition: all 0.3s ease;\n  z-index: 1;\n}\n\n.iconContainer {\n  color: #fff;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50%;\n  width: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));\n  height: calc(50px + (125 - 50) * (1.2px - var(--scale)) / (1.2 - 0.195));\n}\n\n.iconContainer svg {\n  width: calc(32px + (60 - 32) * (1.2px - var(--scale)) / (1.2 - 0.195));\n  height: calc(32px + (60 - 32) * (1.2px - var(--scale)) / (1.2 - 0.195));\n}\n\n.svgContainer {\n  position: absolute;\n  height: auto;\n  z-index: -1;\n}\n\n.svgContainer svg {\n  width: 100%;\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LocationPin_wrapper__NuZgl`,
	"iconContainer": `LocationPin_iconContainer__ikvRu`,
	"svgContainer": `LocationPin_svgContainer__i-2p8`
};
export default ___CSS_LOADER_EXPORT___;
