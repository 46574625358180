import { useTranslation } from 'react-i18next'
import styles from './DocumentPage.module.css'
import { DownloadIcon } from '../../icons/icons'
import { CSSProperties } from 'react'

const DocumentPage: React.FC = () => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language

    const wrapperStyle: CSSProperties = {
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
        left: currentLanguage === 'ar' ? 'unset' : '2%',
        right: currentLanguage === 'ar' ? '2%' : 'unset',
    }

    const directionStyle: CSSProperties = {
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
    }
    return (
        <div className={styles.container}>
            <span className={styles.title}>{t(`documentsPage.title`)}</span>
            <div className={styles.documentsWrapper} style={directionStyle}>
                <div className={styles.documentWrapper} style={{ width: '90%' }}>
                    <img className={styles.documentThumbnail} src='/assets/images/brochure.jpeg' alt='document thumbnail' />
                    <div className={styles.wrapper} style={wrapperStyle}>
                        <div className={styles.documentTitle}>{t(`documentsPage.documentTitle1`)}</div>
                        <a className={styles.downloadButton} href="/assets/documents/Dar Alsadan_Brochure V2.pdf" target='_blank'>
                            <DownloadIcon />
                            <span>{t(`documentsPage.download`)}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentPage
