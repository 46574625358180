// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageCarousel_carouselContainer__cfmV- {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.ImageCarousel_carouselButton__Uk9O2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #34333a;
  color: #000;
  border: none;
  border-radius: 50%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2%;
  transition: background-color 0.3s ease;
}

.ImageCarousel_carouselButton__Uk9O2:hover {
  background-color: #fff;
}
.ImageCarousel_carouselButton__Uk9O2 svg * {
  stroke: #fff;
  transition: stroke 0.3s ease;
}
.ImageCarousel_carouselButton__Uk9O2:hover svg * {
  stroke: #111018;
}

.ImageCarousel_leftButton__nLg6J {
  left: 10px;
}

.ImageCarousel_rightButton__Faogb {
  right: 10px;
}

.ImageCarousel_carouselImage__PmiBm {
  width: 90%;
  max-width: 800px;
  height: 256px;
  border-radius: 16px;
  object-fit: cover;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ImageCarousel_carouselImage__PmiBm {
    width: 90%;
    max-width: 800px;
    height: 215px;
  }
}

@media (max-width: 767px) {
  .ImageCarousel_carouselImage__PmiBm {
    height: 190px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/ImageCarousel/ImageCarousel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,sCAAsC;AACxC;;AAEA;EACE,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,4BAA4B;AAC9B;AACA;EACE,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,UAAU;IACV,gBAAgB;IAChB,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".carouselContainer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  width: 100%;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.carouselButton {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  background-color: #34333a;\n  color: #000;\n  border: none;\n  border-radius: 50%;\n  aspect-ratio: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  padding: 2%;\n  transition: background-color 0.3s ease;\n}\n\n.carouselButton:hover {\n  background-color: #fff;\n}\n.carouselButton svg * {\n  stroke: #fff;\n  transition: stroke 0.3s ease;\n}\n.carouselButton:hover svg * {\n  stroke: #111018;\n}\n\n.leftButton {\n  left: 10px;\n}\n\n.rightButton {\n  right: 10px;\n}\n\n.carouselImage {\n  width: 90%;\n  max-width: 800px;\n  height: 256px;\n  border-radius: 16px;\n  object-fit: cover;\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .carouselImage {\n    width: 90%;\n    max-width: 800px;\n    height: 215px;\n  }\n}\n\n@media (max-width: 767px) {\n  .carouselImage {\n    height: 190px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carouselContainer": `ImageCarousel_carouselContainer__cfmV-`,
	"carouselButton": `ImageCarousel_carouselButton__Uk9O2`,
	"leftButton": `ImageCarousel_leftButton__nLg6J`,
	"rightButton": `ImageCarousel_rightButton__Faogb`,
	"carouselImage": `ImageCarousel_carouselImage__PmiBm`
};
export default ___CSS_LOADER_EXPORT___;
