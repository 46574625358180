// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZoomToolbar_container__A8aVo {
  background-color: #0000004d;
  border-radius: 12px;
  padding: 0px 6px;
  height: 50px;
  position: absolute;
  top: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  box-sizing: border-box;
  gap: 4px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .ZoomToolbar_container__A8aVo {
    top: 2%;
  }
}

@media (max-width: 767px) {
  .ZoomToolbar_container__A8aVo {
    top: 2%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/ZoomToolbar/ZoomToolbar.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU;EACV,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE;IACE,OAAO;EACT;AACF;;AAEA;EACE;IACE,OAAO;EACT;AACF","sourcesContent":[".container {\n  background-color: #0000004d;\n  border-radius: 12px;\n  padding: 0px 6px;\n  height: 50px;\n  position: absolute;\n  top: 1%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 1;\n  box-sizing: border-box;\n  gap: 4px;\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .container {\n    top: 2%;\n  }\n}\n\n@media (max-width: 767px) {\n  .container {\n    top: 2%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZoomToolbar_container__A8aVo`
};
export default ___CSS_LOADER_EXPORT___;
