// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationTabButton_container__aSpUr {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 10px;
  border-radius: 16px;
  width: 80px; /* Ensure the active button has the same width */
  height: 72px;
  margin: 0px 2px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.NavigationTabButton_container__aSpUr:hover {
  background-color: #000000;
}

.NavigationTabButton_text__EZKS\\+ {
  font-size: var(--font-size-4);
  color: #a1a1a1;
  margin-top: 8px;
  font-family: var(--font-medium), sans-serif;
}

.NavigationTabButton_activeText__8brvI {
  color: #fff;
}

.NavigationTabButton_active__BBq-Y {
  background: linear-gradient(to bottom right, #d27bfa, #3d55f8) !important;
  width: 80px; /* Ensure the active button has the same width */
  height: 72px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/NavigationTabButton/NavigationTabButton.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW,EAAE,gDAAgD;EAC7D,YAAY;EACZ,eAAe;EACf,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,eAAe;EACf,2CAA2C;AAC7C;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yEAAyE;EACzE,WAAW,EAAE,gDAAgD;EAC7D,YAAY;AACd","sourcesContent":[".container {\n  background-color: transparent;\n  border: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  padding: 12px 10px;\n  border-radius: 16px;\n  width: 80px; /* Ensure the active button has the same width */\n  height: 72px;\n  margin: 0px 2px;\n  transition: background-color 0.3s ease;\n  cursor: pointer;\n}\n\n.container:hover {\n  background-color: #000000;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  color: #a1a1a1;\n  margin-top: 8px;\n  font-family: var(--font-medium), sans-serif;\n}\n\n.activeText {\n  color: #fff;\n}\n\n.active {\n  background: linear-gradient(to bottom right, #d27bfa, #3d55f8) !important;\n  width: 80px; /* Ensure the active button has the same width */\n  height: 72px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NavigationTabButton_container__aSpUr`,
	"text": `NavigationTabButton_text__EZKS+`,
	"activeText": `NavigationTabButton_activeText__8brvI`,
	"active": `NavigationTabButton_active__BBq-Y`
};
export default ___CSS_LOADER_EXPORT___;
