import styles from './Logo.module.css';
import { LedarLogoIconSmall } from '../../../icons/icons';
import { useNavigate } from 'react-router-dom';

export default function Logo() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className={styles.container} onClick={handleLogoClick}>
      <LedarLogoIconSmall color={'#fff'} />
    </div>
  );
}
