import i18n from '../../../i18n'
import { Position } from './LocationPin'
import styles from './LocationPinText.module.css'

interface Props {
  type: string
  name: string
  position: Position
  positionClicked: string | null
  hoveredLocation: string | null
  localisationData: {
    distance: number
    duration: number
  }
}

export default function LocationPinText({
  type,
  name,
  position,
  positionClicked,
  localisationData,
  hoveredLocation,
}: Props) {
  const currentLanguage = i18n.language

  const style = {
    left: `calc(${position.mobile?.left ?? position.desktop.left} + 1.3%)`,
    top: position.mobile?.top ?? position.desktop.top,
    '@media (minWidth: 768px)': {
      left: `calc(${position.tablet?.left ?? position.desktop.left} + 1.2%)`,
      top: position.tablet?.top ?? position.desktop.top,
    },
    '@media (minWidth: 1024px)': {
      left: `calc(${position.desktop.left} + 1.2%)`,
      top: position.desktop.top,
    },
  }

  const getBackgroundColor = () => {
    switch (type) {
      case `otherAmenities`:
        return '#f89248'
      case `residential`:
        return '#ff6767'
      case `retail`:
        return '#F8D648'
      case `health`:
        return '#7abc1d'
      case `business`:
        return '#6c82f7'
      case `religious`:
        return '#3353F8'
      case `education`:
        return '#987BFA'
      default:
        return ''
    }
  }

  return (
    <div
      className={[
        styles.locationName,
        positionClicked === name && styles.active,
        hoveredLocation === name && styles.hovered,
      ].join(' ')}
      style={{
        ...style,
        backgroundColor: getBackgroundColor(),
        textAlign: currentLanguage === 'ar' ? 'right' : 'left',
      }}
    >
      <span style={{ direction: currentLanguage === 'ar' ? 'rtl' : 'ltr' }}>{name}</span>
      {positionClicked === name && (
        <div
          className={styles.localisationData}
          style={{
            backgroundColor: getBackgroundColor(),
            marginTop: currentLanguage === 'ar' ? '-0.38em' : '0.28em',
          }}
        >
          <span>{localisationData.distance} km</span>
          <span>{localisationData.duration} min</span>
        </div>
      )}
    </div>
  )
}
