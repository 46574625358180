import { useContext } from 'react';
import { PageContext } from '../../../contexts';
import { CaptionIcon } from '../../../icons/icons';
import styles from './CaptionButton.module.css';
import { useTranslation } from 'react-i18next';

const CaptionButton: React.FC = () => {
  const { t } = useTranslation();
  const { areCaptionsOn, toggleCaptions } = useContext(PageContext);

  const handleCaptionToggle = () => {
    toggleCaptions(!areCaptionsOn); // Toggle the state
  };

  return (
    <div className={styles.container} onClick={handleCaptionToggle}>
      <CaptionIcon />
      <div className={styles.text}>{areCaptionsOn ? t(`button.hideCaption`) : t(`button.showCaption`)}</div>
    </div>
  );
};

export default CaptionButton;
