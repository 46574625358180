import styles from './GlobePage.module.css';
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab';
import MapLocation from '../../components/Shared/MapLocation/MapLocation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function GlobePage() {
  const { t } = useTranslation();

  useEffect(() => {
    const videoElement = document.querySelector(`.${styles.video}`) as HTMLVideoElement;
    const tabletBreakpoint = 768;

    if (videoElement && window.innerWidth < tabletBreakpoint) {
      videoElement.pause();
    }
  }, []);

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    };

    // Initial set
    setBodyHeight();

    // Update on resize
    window.addEventListener('resize', setBodyHeight);

    // Cleanup
    return () => {
      window.removeEventListener('resize', setBodyHeight);
    };
  }, []);

  const handleVideoError = () => {
    const videoContainer = document.querySelector(`.${styles.videoBackground}`);
    if (videoContainer) {
      videoContainer.classList.add(styles.videoFail);
    }
  };

  return (
    <div className={styles.videoBackground}>
      {/* Video for larger screens */}
      <video autoPlay loop muted className={styles.video} onError={handleVideoError}>
        <source src="/assets/videos/AnimationGlobeBoucle.mp4" type="video/mp4" />
      </video>

      {/* Fallback image for desktop */}
      <div className={styles.desktopFallback}></div>

      {/* Image for mobile */}
      <div className={styles.mobileBackground}></div>

      {/* Content */}
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.welcome}>{t(`welcomeTo`)}</div>
          <div className={styles.ledar}>{t(`ledarWord`)}</div>
        </div>
        <MapLocation
          targetPage="/country"
          locationName={t(`locations.country`)}
          position={{
            mobile: { top: '66%', left: '58%' },
            desktop: { top: '68%', left: '48%' },
          }}
        />

        <NavigationTab locationName={t(`locations.world`)} />
      </div>
    </div>
  );
}
