import { useContext, useEffect, useState } from 'react'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import ZoomToolbar from '../../components/Shared/ZoomToolbar/ZoomToolbar'
import { PageContext } from '../../contexts'
import styles from './ResidencyPage.module.css'
import PropertyFilters from '../../components/Shared/PropertyFilters/PropertyFilters'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import UnitInformations from '../../components/Shared/UnitInformations/UnitInformations'
import MobileUnitInformations from '../../components/Shared/MobileUnitInformations/MobileUnitInformations'
import TabletUnitInformations from '../../components/Shared/TabletUnitInformations/TabletUnitInformations'
import { AnimatePresence, motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import { useLocation } from 'react-router-dom'

export default function ResidencyPage() {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const location = useLocation()
  const { activeButton, displayedProperty } = useContext(PageContext)
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 568)

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 568)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const compassPosition: Position = {
    mobile: {
      en: {
        right: '3%',
        top: '10%',
      },
      ar: {
        left: '3%',
        top: '10%',
      },
    },
    desktop: {
      en: {
        bottom: '5%',
        left: '2%',
      },
      ar: {
        bottom: '5%',
        right: '2%',
      },
    },
  }

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '100%' : '-100%',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '100%' : '-100%',
    },
  }

  const unitSlideVariants = {
    hidden: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '-100%' : '100%',
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: currentLanguage === 'ar' ? '-100%' : '100%',
    },
  }

  const slideUpVariants = {
    hidden: {
      opacity: 0,
      y: '100%',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: '100%',
    },
  }

  const getStylesForDeviceAndLanguage = () => {
    if (isMobileView) {
      return {
        right: '0%',
        left: '0%',
      }
    }

    if (currentLanguage === 'ar') {
      return {
        right: '2%',
        left: 'unset',
      }
    }

    return {
      right: 'unset',
      left: '2%',
    }
  }

  const deviceAndLanguageStyles = getStylesForDeviceAndLanguage()

  useEffect(() => {
    const preventDefaultScroll = (e: Event | TouchEvent) => {
      e.preventDefault()
    }

    if (activeButton === t(`nav.filters`)) {
      // Disable global scrolling
      document.addEventListener('touchmove', preventDefaultScroll as any, {
        passive: false,
      })
      document.body.style.overflow = 'hidden'
    } else {
      // Enable global scrolling
      document.removeEventListener('touchmove', preventDefaultScroll as any)
      document.body.style.overflow = ''
    }

    return () => {
      // Cleanup: Enable global scrolling when component unmounts
      document.removeEventListener('touchmove', preventDefaultScroll as any)
      document.body.style.overflow = ''
    }
  }, [activeButton, t])

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    setBodyHeight()

    window.addEventListener('resize', setBodyHeight)

    return () => {
      window.removeEventListener('resize', setBodyHeight)
    }
  }, [])

  const height = CSS.supports('height', '100svh') ? '100svh' : '100vh'

  return (
    <div style={{ width: '100%', height }}>
      <ZoomToolbar />
      <AnimatePresence>
        {activeButton === t(`nav.filters`) && (
          <motion.div
            variants={slideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            className={styles.propertyFilterAnimatedContainer}
            style={{
              ...deviceAndLanguageStyles,
              pointerEvents: 'none',
            }}
          >
            <PropertyFilters locationName={location.pathname} />
          </motion.div>
        )}
      </AnimatePresence>
      <NavigationTab locationName={t(`locations.residency`)} />
      <HorizontalTaletMobileFilters />
      <AnimatePresence>
        {activeButton === 'unitInformation' && displayedProperty && (
          <motion.div
            variants={unitSlideVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
            className={styles.animatedContainer}
            style={{
              right: currentLanguage !== 'ar' ? 0 : 'unset',
              left: currentLanguage === 'ar' ? 0 : 'unset',
            }}
          >
            <UnitInformations />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <AnimatePresence>
          {activeButton === 'unitInformation' && displayedProperty && isMobile && (
            <motion.div
              variants={slideUpVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              style={{
                position: 'fixed',
                bottom: '0%',
                width: '100%',
                height: '100%',
                zIndex: 3000,
              }}
            >
              {isMobileView ? <MobileUnitInformations /> : <TabletUnitInformations />}
            </motion.div>
          )}
        </AnimatePresence>
      </AnimatePresence>
      <Compass position={compassPosition} />
      <AnimatePresence>
        <AnimatePresence>
          {activeButton === 'unitInformation' && displayedProperty && isMobile && (
            <motion.div
              variants={slideUpVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              transition={{ duration: 0.3 }}
              style={{
                position: 'fixed',
                bottom: '0%',
                width: '100%',
                height: '100%',
                zIndex: 3000,
              }}
            >
              {isMobileView ? <MobileUnitInformations /> : <TabletUnitInformations />}
            </motion.div>
          )}
        </AnimatePresence>
      </AnimatePresence>
    </div>
  )
}
