import { useContext, useEffect, useState } from 'react';
import { PageContext } from '../../../contexts';
import styles from './Captions.module.css';
import { useTranslation } from 'react-i18next';

type CaptionsProps = {
  floor: number;
  plan: string;
};

const Captions: React.FC<CaptionsProps> = (Props: CaptionsProps) => {
  const { t } = useTranslation();
  const { areCaptionsOn, setIsVRMode, setCameraUrl, displayedProperty } = useContext(PageContext);
  const [captionsToDisplay, setCaptionsToDisplay] = useState<any[]>([]);

  useEffect(() => {
    if (displayedProperty) {
      const filteredCameras = displayedProperty.property_type?.floors
        .find((floor) => floor.level === Props.floor)
        ?.points.filter((point) => point.is_visible)
        .map((point) => ({
          ...point,
          key: point.room
            .replace(/([a-z])\.([a-z])|(\s)([a-z])|(\+)(\s)([a-z])/g, (match, p1, p2, p3, p4, p5, p6, p7) =>
              p1 ? p1 + p2.toUpperCase() : p3 ? p3 + p4.toUpperCase() : p5 + p7.toUpperCase()
            )
            .replace(/\.|\s|\+/g, '')
            .replace(/^(.)/, (match) => match.toLowerCase()),
        }));
      setCaptionsToDisplay(filteredCameras ?? []);
    }
  }, [displayedProperty, Props.floor]);

  const handleOnClick = (cameraUrl: string) => {
    setIsVRMode(true);
    setCameraUrl(cameraUrl);
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${Props.plan})` }}>
      {areCaptionsOn &&
        captionsToDisplay.map((caption) => (
          <div
            key={caption.alias}
            className={`${styles.caption}`}
            style={{
              top: (caption.position.top ?? 0) + '%',
              left: (caption.position.left ?? 0) + '%',
              cursor: caption.url ? 'pointer' : 'default',
            }}
            onClick={() => (caption.url ? handleOnClick(caption.url) : null)}
            data-url={caption.alias}
          >
            {t(`captions.${caption.key}`)}
          </div>
        ))}
    </div>
  );
};

export default Captions;
