import styles from './MobileTabletCallbackForm.module.css';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInputWithCountry from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select, { StylesConfig } from 'react-select';
import { CSSObject } from '@emotion/react';

type OptionType = {
  label: string;
  value: string;
};

const customStyles: StylesConfig<OptionType, false> = {
  control: (provided: CSSObject, state: any): CSSObject => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#fff',
    borderColor: state.isFocused ? '#d27bfa' : '#292830',
    height: '50px',
    cursor: 'pointer',
    borderWidth: '1.5px',
    borderRadius: '16px',
    outline: 'none',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    transition: 'border-color 0.3s ease',
    fontSize: '16px',
    marginBottom: '16px',
    '&:hover': {
      backgroundColor: '#ffffff0a',
    },
  }),
  menu: (provided: CSSObject): CSSObject => ({
    ...provided,
    backgroundColor: '#111018',
    borderRadius: '16px',
    overflow: 'hidden',
  }),
  option: (provided: CSSObject, state: any): CSSObject => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? '#292830' : 'initial',
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 0.3s ease',
    fontSize: '16px',
    fontFamily: 'var(--font-medium), sans-serif;',
  }),
  singleValue: (provided: CSSObject, state: any): CSSObject => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: (provided: CSSObject): CSSObject => ({
    ...provided,
    color: '#fff',
    fontSize: '16px',
  }),
  indicatorSeparator: (provided: CSSObject): CSSObject => ({
    ...provided,
    display: 'none',
  }),
};

const MobileTabletCallbackForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [value, setValue] = useState<string | undefined>(undefined);
  const [isPhoneInputFocused, setIsPhoneInputFocused] = useState(false);

  // State for form fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  // State for error messages
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  // Validation functions
  const validateName = (name: string) => /^(?=.*[\u0600-\u06FFa-zA-Z])[\u0600-\u06FFa-zA-Z\s]+$/.test(name);

  const validateEmail = (email: string) => /^[a-zA-Z0-9._%+-\u0600-\u06FF]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  const subjectOptions: OptionType[] = [
    { value: 'subject1', label: t(`callback.subject1`) },
    { value: 'subject2', label: t(`callback.subject2`) },
  ];

  // OnChange handlers
  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFirstName(value);
    setFirstNameError(validateName(value) ? '' : t('callback.invalidFirstName'));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLastName(value);
    setLastNameError(validateName(value) ? '' : t('callback.invalidLastName'));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(validateEmail(value) ? '' : t('callback.invalidEmail'));
  };

  const containerStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  const containerClass = currentLanguage === 'ar' ? styles.arabic : '';

  return (
    <form className={`${styles.container} ${containerClass}`} style={containerStyle}>
      <div className={styles.tabletRow}>
        <div>
          <input
            type="text"
            className={styles.input}
            placeholder={t(`callback.firstName`)}
            onChange={handleFirstNameChange}
            value={firstName}
          />
          <div
            className={styles.error}
            style={{
              opacity: firstNameError ? 1 : 0,
              maxHeight: firstNameError ? '50px' : '0',
            }}
          >
            {firstNameError}
          </div>
        </div>
        <div>
          <input
            type="text"
            className={styles.input}
            placeholder={t(`callback.lastName`)}
            value={lastName}
            onChange={handleLastNameChange}
          />
          <div
            className={styles.error}
            style={{
              opacity: lastNameError ? 1 : 0,
              maxHeight: lastNameError ? '50px' : '0',
            }}
          >
            {lastNameError}
          </div>
        </div>
      </div>
      <div className={styles.tabletRow}>
        <div>
          <input
            type="email"
            className={styles.input}
            placeholder={t(`callback.email`)}
            value={email}
            onChange={handleEmailChange}
          />
          <div
            className={styles.error}
            style={{
              opacity: emailError ? 1 : 0,
              maxHeight: emailError ? '50px' : '0',
            }}
          >
            {emailError}
          </div>
        </div>
        <div>
          <PhoneInputWithCountry
            placeholder="Enter phone number"
            international
            defaultCountry="AE"
            value={value}
            onChange={setValue}
            className={styles.PhoneInput}
            onFocus={() => setIsPhoneInputFocused(true)}
            onBlur={() => setIsPhoneInputFocused(false)}
            style={{
              borderColor: isPhoneInputFocused ? '#d27bfa' : '#292830',
            }}
          />
        </div>
      </div>

      {/* <div
        className={`${styles.selectWrapper} ${
          isSelectFocused ? styles.focused : ""
        }`}
      >
        <select
          onFocus={() => setIsSelectFocused(true)}
          onBlur={() => setIsSelectFocused(false)}
          className={`${styles.select} ${
            currentLanguage === "ar" ? styles.rtl : ""
          }`}
        >
          <option selected disabled hidden value="">
            {t(`callback.chooseObject`)}
          </option>
          <option value="">{t(`callback.subject1`)}</option>
          <option value="">{t(`callback.subject2`)}</option>
        </select>
        <FAQArrowDownIcon />
      </div> */}
      {/* <Select options={subjectOptions} styles={customStyles} placeholder={t(`callback.chooseObject`)} /> */}
      {/* <textarea name="" id="" rows={5} className={styles.input} placeholder={t(`callback.writeSomething`)} /> */}
      <button className={styles.formButton} onClick={(e) => e.preventDefault()}>
        {t(`callback.send`)}
      </button>
    </form>
  );
};

export default MobileTabletCallbackForm;
