import { CSSProperties, useContext, useEffect, useState } from 'react';
import { PageContext } from '../../../contexts';
import { DoubleArrowRighttIcon, DoubleArrowLeftIcon } from '../../../icons/icons';
import styles from './PropertyInformations.module.css';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useConvertValues from '../../../hooks/useConvertValues';
import { useGetStatusQuery } from '../../../services/properties';

const PropertyInformations: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { data: statuses } = useGetStatusQuery({});
  const { setActiveButton, displayedProperty } = useContext(PageContext);
  const [selectedAreaUnit, setSelectedAreaUnit] = useState('SQM');
  const [selectedCurrency, setSelectedCurrency] = useState('SAR');
  const convertedArea = useConvertValues({ value: displayedProperty?.unit_size, option: selectedAreaUnit });
  const convertedPrice = useConvertValues({ value: displayedProperty?.moh_price, option: selectedCurrency });
  const [currentStatus, setCurrentStatus] = useState('available');

  useEffect(() => {
    if (displayedProperty) {
      const status = statuses?.find((status) => status.id === displayedProperty.property_status_id);
      setCurrentStatus(status?.name ?? 'available');
    }
  }, [displayedProperty, statuses]);

  const containerStyle: CSSProperties & { [key: string]: any } = {
    right: currentLanguage === 'ar' ? 'unset' : '0%',
    left: currentLanguage === 'ar' ? '0%' : 'unset',
    '--direction': currentLanguage === 'ar' ? '-100%' : '100%',
  };

  const closeButtonStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
  };

  const closeButtonContainerStyle: CSSProperties = {
    justifyContent: currentLanguage === 'ar' ? 'flex-end' : 'flex-start',
    display: 'flex',
    padding: '7%',
  };

  const titleWrapperStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
  };

  const descriptionStyle: CSSProperties = {
    textAlign: currentLanguage === 'ar' ? 'right' : 'left',
  };

  const rowStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
  };

  const handleClose = () => {
    setActiveButton(null);
  };

  const handleCallbackClick = () => {
    setActiveButton(t(`nav.callback`));
  };

  const handleRegisterClick = () => {
    setActiveButton('register');
  };

  if (isMobile) {
    return null;
  }

  return (
    <div className={styles.container} style={containerStyle}>
      <div style={closeButtonContainerStyle}>
        <button className={styles.closeButton} style={closeButtonStyle} onClick={handleClose}>
          <div className={styles.iconWrapper}>
            {currentLanguage === 'ar' ? <DoubleArrowLeftIcon /> : <DoubleArrowRighttIcon />}
          </div>
          <div className={styles.closeButtonText}>{t(`propertyInformations.hide`)}</div>
        </button>
      </div>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={styles.title}>{t(`propertyInformations.unit`, { id: displayedProperty?.name })}</div>
        <div className={[styles.availability, styles[currentStatus ?? 'available']].join(' ')}>
          {currentStatus ?? t(`propertyInformations.available`)}
        </div>
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {t(`propertyInformations.description`)}
      </p>
      <ImageCarousel
        images={[
          '/assets/images/carrousel-image1.jpeg',
          '/assets/images/carrousel-image2.jpg',
          '/assets/images/carrousel-image3.jpg',
          '/assets/images/carrousel-image4.jpg',
          '/assets/images/carrousel-image5.jpg',
          '/assets/images/carrousel-image6.jpg',
          '/assets/images/carrousel-image7.jpg',
        ]}
      />
      <div className={styles.table}>
        <div className={styles.row} style={rowStyle}>
          <span>{t(`propertyInformations.bedrooms`)}</span>
          <span>{displayedProperty?.number_bedrooms}</span>
        </div>
        <div className={styles.row} style={rowStyle}>
          <span>{t(`propertyInformations.bathrooms`)}</span>
          <span>{displayedProperty?.number_bathrooms}</span>
        </div>
        <div className={styles.row} style={rowStyle}>
          <span>{t(`propertyInformations.floor`)}</span>
          <span>{displayedProperty?.property_type?.floors.length}</span>
        </div>
        <div className={styles.row} style={rowStyle}>
          <span>{t(`propertyInformations.totalArea`)}</span>
          <div>
            <span>{convertedArea} </span>
            <select
              value={selectedAreaUnit}
              onChange={(e) => setSelectedAreaUnit(e.target.value)}
              className={styles.areaSelect}
            >
              <option value="SQM">SQM</option>
              <option value="SQFT">SQFT</option>
            </select>
          </div>
        </div>
      </div>
      <div className={styles.priceWrapper} style={rowStyle}>
        <div className={styles.price}>{t(`propertyInformations.price`)}</div>
        <div className={styles.priceAmountWrapper} style={rowStyle}>
          <div className={styles.priceAmount}>{convertedPrice}</div>
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className={styles.currencySelect}
          >
            <option value="SAR">SAR</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
      </div>

      <div className={styles.buttonWrapper} style={rowStyle}>
        <button className={styles.register} onClick={handleRegisterClick}>
          {t(`propertyInformations.register`)}
        </button>
        <button className={styles.callback} onClick={handleCallbackClick}>
          {t(`propertyInformations.callback`)}
        </button>
      </div>
    </div>
  );
};

export default PropertyInformations;
