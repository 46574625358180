import { useContext } from 'react';
import { PageContext } from '../../../contexts';
import { SwitchIcon } from '../../../icons/icons';
import styles from './VRModeButton.module.css';
import { useTranslation } from 'react-i18next';

const VRModeButton: React.FC = () => {
  const { t } = useTranslation();
  const { isVRMode, toggleVRMode } = useContext(PageContext);
  return (
    <button className={styles.container} onClick={toggleVRMode}>
      <SwitchIcon />
      <div className={styles.text}>{isVRMode ? t(`button.floorPlan`) : t(`button.vr`)}</div>
    </button>
  );
};

export default VRModeButton;
