import { useTranslation } from 'react-i18next';
import styles from './VideoPage.module.css';
import { isMobile } from 'react-device-detect';
import MobileTabletVideoPage from './MobileTablet/MobileTabletVideoPage';
import { CSSProperties, useContext, useState } from 'react';
import VideoModal from '../../components/Modal/VideoModal/VideoModal';
import { PlayerIcon } from '../../icons/icons';
import { AnimatePresence } from 'framer-motion';
import { PageContext } from '../../contexts';

const VideoPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [videoSrc, setVideoSrc] = useState<string>('');
  const { isModalOpen, setIsModalOpen } = useContext(PageContext);

  const handleVideoClick = (src: string) => {
    setVideoSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isMobile) {
    return <MobileTabletVideoPage />;
  }

  const directionStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };
  return (
    <div className={styles.container}>
      <span className={styles.title}>{t(`videoPage.title`)}</span>
      <div className={styles.featuredVideosWrapper}>
        <video src="/assets/videos/DarAlsadan-MainVideo.mp4" controls style={{height: "65vh"}}></video>
      </div>
    
      <AnimatePresence>
        {isModalOpen && <VideoModal show={isModalOpen} onClose={closeModal} initialVideoSrc={videoSrc} />}
      </AnimatePresence>
    </div>
  );
};

export default VideoPage;
