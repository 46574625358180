import GoogleMapReact from 'google-map-react'
import { useContext, useEffect, useRef, useState } from 'react'
import { PageContext } from '../../../contexts'

import animatePolylines from '../../../utils/animatedPolyline'

import { Property } from '../../../types/property'
import { Status } from '../../../types/status'

import { residencyPageAmenityPositions, getAmenityCards } from '../../../utils/amenityCardPositions'
import { useLazyGetPropertyQuery } from '../../../services/properties'
import Disclamer from '../Disclamer/Disclamer'
import { Position } from '../Compass/Compass'

const transitions = {}

//@ts-ignore
function clearTransition(poly) {
  //@ts-ignore
  if (transitions[poly.id]) {
    //@ts-ignore
    clearInterval(transitions[poly.id])
    //@ts-ignore
    delete transitions[poly.id]
  }
}

//@ts-ignore
function smoothTransition(poly, targetOpacity, interval = 10, step = 0.01) {
  clearTransition(poly) // Clear any existing transition on this poly

  const currentOpacity = poly.get('fillOpacity')
  const direction = targetOpacity > currentOpacity ? 1 : -1

  //@ts-ignore
  transitions[poly.id] = setInterval(() => {
    let newOpacity = poly.get('fillOpacity') + step * direction
    if ((direction === 1 && newOpacity > targetOpacity) || (direction === -1 && newOpacity < targetOpacity)) {
      newOpacity = targetOpacity
    }

    poly.setOptions({ fillOpacity: newOpacity, strokeOpacity: newOpacity })

    if (newOpacity === targetOpacity) {
      //@ts-ignore
      clearInterval(transitions[poly.id])
      //@ts-ignore
      delete transitions[poly.id]
    }
  }, interval)
}

const disclamerPosition: Position = {
  mobile: {
    en: {
      top: '10%',
      left: '4%',
    },
    ar: {
      left: '3%',
      top: '7%',
    },
  },
  desktop: {
    en: {
      bottom: '5%',
      right: '2%',
    },
    ar: {
      bottom: '5%',
      left: '2%',
    },
  },
}

export default function ResidencyMap({ properties, statuses }: { properties: Property[]; statuses: Status[] }) {
  const {
    displayedProperty,
    setDisplayedProperty,
    setActiveButton,
    getMapFilter,
    mapFilters,
    setPolygones,
    polygones,
    setDisplayedPolygones,
    displayedPolygones,
    mapZoom,
    activeAmenitiesButtons,
    mapCenter,
    setMapCenter,
  } = useContext(PageContext)
  const [getProperty] = useLazyGetPropertyQuery()
  const propertiesRef = useRef<Property[]>(properties)
  const statusesRef = useRef<Status[]>(statuses)
  const newPolygones = useRef<any[]>([])
  const mapRef = useRef<any>(null)
  const mapsRef = useRef<any>(null)
  const currentInfoWindow = useRef<any>(null)
  const amenityCards = getAmenityCards(activeAmenitiesButtons, residencyPageAmenityPositions)
  const amenitiesRef = useRef<any[]>([])
  const displayedPropertyRef = useRef<Property | null>(null)

  useEffect(() => {
    displayedPropertyRef.current = displayedProperty
  }, [displayedProperty])

  const updatePolygones = () => {
    setPolygones(newPolygones.current)
  }

  let animatedPolyline: {
    path: any[]
    geodesic: boolean
    strokeColor: string
    strokeOpacity: number
    strokeWeight: number
    editable: boolean
    map: any
    animationSpeed: number
  }[] = [
    {
      path: [
        [-151, 32.5],
        [-151, 27.5],
        [-46, 27.5],
        [-46, 46.5],
      ],
      geodesic: true,
      strokeColor: '#0000FF',
      strokeOpacity: 1.0,
      strokeWeight: 10,
      editable: false,
      map: null,
      animationSpeed: 100000,
    },
  ]

  useEffect(() => {
    if (properties) {
      propertiesRef.current = properties
    }
  }, [properties])

  useEffect(() => {
    if (statuses) {
      statusesRef.current = statuses
    }
  }, [statuses])

  const [amenityPoints, setAmenityPoints] = useState<any[]>([])

  useEffect(() => {
    amenitiesRef.current = amenityCards

    if (!mapRef.current || !mapsRef.current) {
      return
    }

    amenityPoints.forEach(point => {
      const alreadyExistingPoint = amenityCards.find(
        amenity => JSON.stringify(amenity.position) === JSON.stringify(point.latLng)
      )

      if (!alreadyExistingPoint) {
        point.marker.setMap(null)
        amenityPoints.splice(amenityPoints.indexOf(point), 1)
      }
    })

    amenityCards.forEach((amenity: any) => {
      const existingAmenity = amenityPoints.find(
        point => JSON.stringify(point.latLng) === JSON.stringify(amenity.position)
      )

      if (!existingAmenity) {
        const point = new mapsRef.current.LatLng(amenity.position[1], amenity.position[0])

        const renderIcon = (iconType: string) => {
          if (iconType.includes('hospital')) {
            return require('../../../icons/amenity/hospital.svg')
          } else if (iconType.includes('park')) {
            return require('../../../icons/amenity/park.svg')
          } else if (iconType.includes('retail')) {
            return require('../../../icons/amenity/retail.svg')
          } else if (iconType.includes('pocketPark')) {
            return require('../../../icons/amenity/pocket.svg')
          } else if (iconType.includes('mosque')) {
            return require('../../../icons/amenity/religious.svg')
          } else if (iconType.includes('school')) {
            return require('../../../icons/amenity/education.svg')
          } else {
            return null
          }
        }

        const marker = new mapsRef.current.Marker({
          position: point,
          map: mapRef.current,
          icon: {
            url: renderIcon(amenity.iconType).default,
            scaledSize: new mapsRef.current.Size(28, 28),
          },
          animation: mapsRef.current.Animation.DROP,
        })

        marker.addListener('mouseover', (mouseEvent: any) => {
          openInfoWindow(mapRef.current, mapsRef.current, amenity, mouseEvent)
        })

        marker.addListener('click', (touchEvent: any) => {
          openInfoWindow(mapRef.current, mapsRef.current, amenity, touchEvent)
        })

        marker.addListener('mouseout', () => {
          handleCloseInfoWindow()
        })

        mapRef.current.addListener('click', () => {
          handleCloseInfoWindow()
        })

        setAmenityPoints([...amenityPoints, { latLng: amenity.position, marker }])
      }
    })
  }, [activeAmenitiesButtons, amenityCards, amenityPoints, mapRef, mapsRef, setAmenityPoints])

  const openInfoWindow = (map: any, maps: any, amenity: any, mouseEvent: any) => {
    const { iconType, name } = amenity
    let color: string = ''

    if (iconType.includes('hospital')) {
      color = '#ff6767'
    } else if (iconType.includes('park')) {
      color = '#95d935'
    } else if (iconType.includes('retail')) {
      color = '#D27BF9'
    } else if (iconType.includes('pocketPark')) {
      color = '#6780ff'
    } else if (iconType.includes('mosque')) {
      color = '#F8D648'
    } else if (iconType.includes('school')) {
      color = '#987BFA'
    }

    let infoWindow = new maps.InfoWindow({
      content: `
          <div id="info-window">
            <div class="place-name">
              <span style="color: ${color};">${name}</span>
            </div>
          
        </div>
      `,

      // <div class="place-info">
      //       <img src="/assets/images/mosque.jpg" alt="${name}" class="info-window-image" />
      //       <div class="info-window-content">
      //         <h3>${name}</h3>
      //         <div>Al Fawzen Mosque is a magnificent religious edifice nestled in the heart of Riyadh, the bustling capital city of Saudi Arabia. This architectural marvel stands as a testament to the rich Islamic heritage and cultural identity of the region.</div>
      //       </div>
      //     </div>
    })

    infoWindow.id = mouseEvent.id

    infoWindow.setPosition(mouseEvent.latLng)

    infoWindow.setOptions({
      pixelOffset: new maps.Size(15, 0),
    })

    currentInfoWindow.current?.close()

    currentInfoWindow.current = infoWindow
    infoWindow.open(map)

    // hoverInfoWindow(map, maps, amenity, mouseEvent);
  }

  const handleCloseInfoWindow = () => {
    if (currentInfoWindow.current !== null) {
      currentInfoWindow.current?.close()
    }
  }

  useEffect(() => {
    let tempDisplayedPolygones: any[] = []

    polygones.length > 0 &&
      polygones.forEach(poly => {
        let isVisible = true

        mapFilters.forEach(filter => {
          if (filter.value === 'all') return

          if (filter.key === 'price' || filter.key === 'plotArea') {
            if (
              Array.isArray(filter.value) &&
              (poly[filter.key] < filter.value[0] || poly[filter.key] > filter.value[1])
            ) {
              isVisible = false
            }
          } else {
            if (poly[filter.key] !== filter.value) {
              isVisible = false
            }
          }

          if (filter.key === 'status' && Array.isArray(filter.value) && typeof poly.status === 'string') {
            if ((filter.value as string[]).includes(poly.status)) {
              isVisible = true
            }
          }
        })

        if (displayedProperty?.id === poly.id) {
          poly.setOptions({ fillOpacity: 0, strokeOpacity: 0 })
        } else {
          if (isVisible) {
            tempDisplayedPolygones.push(poly)
            smoothTransition(poly, 0.35)
          } else {
            smoothTransition(poly, 0)
          }
        }

        poly.setOptions({ isVisible: isVisible })
      })

    const oldIds = displayedPolygones.map(poly => poly.id)
    const newIds = tempDisplayedPolygones.map(poly => poly.id)

    if (JSON.stringify(newIds) !== JSON.stringify(oldIds)) {
      setDisplayedPolygones(tempDisplayedPolygones)
    }
  }, [getMapFilter, polygones, setDisplayedPolygones, displayedPolygones, mapFilters])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapZoom)
    }
  }, [mapZoom, mapRef])

  const handleFetchProperty = async (id: number) => {
    const property = await getProperty({ project: 'ledar', id })

    if (property.data) {
      setDisplayedProperty(property.data)
    }
  }

  const handleUnitClick = (property: any) => {
    if (displayedPropertyRef.current?.id !== property.id) {
      setDisplayedProperty(property)
      setActiveButton('unitInformation')

      handleFetchProperty(property.id)
    } else {
      setDisplayedProperty(null)
      setActiveButton(null)
    }
  }

  if (!properties || properties.length === 0 || !statuses || statuses.length === 0) {
    return <div>Loading...</div>
  }

  const height = CSS.supports('height', '100svh') ? '100svh' : '100vh'

  return (
    <div style={{ width: '100%', height }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyB1vWnJNit84C9jqLEHuWMkVtUGwK6ZOlU' }}
        defaultCenter={{ lat: 0, lng: 0 }}
        center={mapCenter}
        defaultZoom={0}
        resetBoundsOnResize={true}
        options={{
          zoomControl: false,
          fullscreenControl: false,
          // @ts-ignore
          restriction: {
            latLngBounds: {
              north: 47.39882751631397,
              south: -47.39882751631397,
              west: -95.96178094070348,
              east: 95.96178094070348,
            },
            // strictBounds: false,
          },
        }}
        onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => {
          mapRef.current = map
          mapsRef.current = maps

          let layer = new maps.ImageMapType({
            name: 'vvsCustomLayer',
            getTileUrl: (coord: any, zoom: any) => {
              return `tilestest3/${zoom}/tile_${coord.x}_${coord.y}.jpg`
            },
            tileSize: new maps.Size(256, 256),
            minZoom: 0,
            maxZoom: 7,
          })

          map.mapTypes.set('vvsCustomLayer', layer)
          map.setMapTypeId('vvsCustomLayer')

          //@ts-ignore
          map.addListener('click', e => {
            console.log({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          })

          let svgIcon1 = {
            path: 'M -20,0 A 20,20 0 1,1 20,0 A 20,20 0 1,1 -20,0 Z', // Circle path
            fillColor: '#000', // Grey fill
            strokeColor: '#000',
            fillOpacity: 0.45,
            strokeOpacity: 0.65,
            strokeWeight: 2,
            scale: 1,
            labelOrigin: new google.maps.Point(0, 0), // Center the label
            anchor: new google.maps.Point(0, 0), // Center the icon
          }

          let svgIcon2 = {
            path: 'M -38,0 A 38,24 0 1,1 38,0 A 38,24 0 1,1 -38,0 Z', // Circle path
            fillColor: '#000', // Grey fill
            strokeColor: '#000',
            fillOpacity: 0.45,
            strokeOpacity: 0.65,
            strokeWeight: 2,
            scale: 1,
            labelOrigin: new google.maps.Point(0, 0), // Center the label
            anchor: new google.maps.Point(0, 0), // Center the icon
          }

          //@ts-ignore
          let markers = []

          //@ts-ignore
          let unzoomedMarkers = []

          ;[
            {
              position: { lat: 22.309425841200188, lng: -58.447265625 },
              label: '1 - 42',
            },
            {
              position: { lat: 9.44906182688142, lng: -38.653254217990764 },
              label: '43 - 84',
            },
            {
              position: { lat: -3.9519408561575817, lng: -37.426624690703484 },
              label: '85 - 126',
            },
            {
              position: { lat: 9.44906182688142, lng: 10.092640315703507 },
              label: '127 - 152',
            },
            {
              position: { lat: -4.0469202469846755, lng: 10.004749690703507 },
              label: '153 - 178',
            },
            {
              position: { lat: 8.950995588804249, lng: 37.575634455846334 },
              label: '179 - 193',
            },
            {
              position: { lat: -4.003826107525045, lng: 39.975452815703505 },
              label: '194 - 212',
            },
            {
              position: { lat: -29.993614288250917, lng: 14.583096489932057 },
              label: '213 - 220',
            },
            {
              position: { lat: -23.322728906884, lng: 32.680530940703505 },
              label: '221 - 242',
            },
            {
              position: { lat: -23.472811237383635, lng: 50.65069341461381 },
              label: '243 - 264',
            },
            {
              position: { lat: -20.71157823904474, lng: 69.24051881955216 },
              label: '265 - 292',
            },
            {
              position: { lat: 3.644878926913774, lng: 66.4744762532035 },
              label: '293 - 310',
            },
            {
              position: { lat: 4.896800130925733, lng: -80.17655274834824 },
              label: '311 - 358',
            },
            {
              position: { lat: -19.917683707411168, lng: -80.30851005176841 },
              label: '320 - 349',
            },
            {
              position: { lat: -22.91792293614603, lng: -62.563343440703484 },
              label: '359 - 380',
            },
            {
              position: { lat: -22.99885159414291, lng: -44.369984065703484 },
              label: '381 - 402',
            },
            {
              position: { lat: -16.839216432640626, lng: -21.20092177428204 },
              label: '403 - 418',
            },
            {
              position: { lat: -17.220149707791684, lng: 6.313934315456153 },
              label: '419 - 438',
            },
          ].map(el => {
            let marker = new google.maps.Marker({
              position: el.position,
              map: map,
              label: {
                text: `${el.label}`, // Ensure the text is at least two digits, e.g., "01"
                color: '#FFFFFF', // White text
                fontSize: '12px',
                fontFamily: 'notokufi-bold',
              },
              visible: true,
              icon: svgIcon2,
            })

            marker.addListener('click', () => {
              map.setZoom(5)
              map.setCenter(marker.getPosition())
            })

            unzoomedMarkers.push(marker)
          })

          properties.length > 0 &&
            properties.forEach(property => {
              let { id, geometry, property_status_id, property_type_id, moh_price, unit_size, id_unit } = property
              const currentStatus = statusesRef.current.find(status => status.id === property_status_id)

              let bounds = new google.maps.LatLngBounds()

              let poly = new maps.Polygon({
                paths: geometry.coordinates[0].map(el => {
                  //@ts-ignore
                  let point = { lat: el[1], lng: el[0] }
                  bounds.extend(point)
                  return point
                }),
                id: id,
                strokeColor: currentStatus?.color,
                strokeOpacity: 0.55,
                strokeWeight: 1,

                fillColor: currentStatus?.color,
                fillOpacity: 0.35,
                status: currentStatus?.name === 'shadow' ? 'sold' : currentStatus?.name,
                unitType: property_type_id,
                price: moh_price,
                plotArea: unit_size,
                visible: true,
              })

              poly.setMap(map)

              mapRef.current = map
              newPolygones.current.push(poly)

              let center = bounds.getCenter()

              // Create a marker at the center with the SVG icon

              let marker = new google.maps.Marker({
                position: center,
                map: map,
                icon: svgIcon1,
                label: {
                  text: `${id_unit}`, // Ensure the text is at least two digits, e.g., "01"
                  color: '#FFFFFF', // White text
                  fontSize: '14px',
                  fontFamily: 'notokufi-bold',
                },
                visible: false,
              })
              markers.push(marker)

              if (currentStatus?.name !== 'shadow') {
                marker.addListener('click', () => {
                  handleUnitClick(property)
                })

                poly.addListener('click', () => {
                  handleUnitClick(property)
                })
              } else {
                console.log(currentStatus?.color)
              }

              poly.addListener('mouseover', () => {
                newPolygones.current.forEach(cpoly => {
                  if (poly.id === cpoly.id || cpoly.id === displayedPropertyRef.current?.id) {
                    smoothTransition(poly, 0)
                  } else {
                    if (cpoly.fillOpacity !== 0.35 && displayedPolygones.includes(poly.id)) {
                      smoothTransition(cpoly, 0.35)
                    }
                  }
                })
              })

              poly.addListener('mouseout', () => {
                if (poly.id === displayedPropertyRef.current?.id) {
                } else {
                  if (poly.isVisible) {
                    smoothTransition(poly, 0.35)
                  }
                }
              })
            })

          // map.addListener('zoom_changed', () => {

          // })

          map.addListener('idle', () => {
            var currentZoom = map.getZoom()
            const bounds = map.getBounds()

            //@ts-ignore
            markers.forEach(function (marker, i) {
              if (currentZoom <= 4) {
                marker.setVisible(false)
              } else {
                // setTimeout(() => {
                if (bounds.contains(marker.getPosition())) {
                  marker.setVisible(true)
                } else {
                  marker.setVisible(false)
                }
                // }, 10 * i)
              }
            })

            //@ts-ignore
            unzoomedMarkers.forEach(function (marker, i) {
              if (currentZoom <= 4) {
                if (bounds.contains(marker.getPosition())) {
                  marker.setVisible(true)
                } else {
                  marker.setVisible(false)
                }
              } else {
                marker.setVisible(false)
              }
            })
          })
          updatePolygones()
        }}
      />

      <Disclamer position={disclamerPosition} />
    </div>
  )
}
