import React, { useContext, useRef } from 'react';
import AmenitiesButton from '../AmenitiesButton/AmenitiesButton';
import styles from './Amenities.module.css';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../../contexts';
import { residencyPageAmenityPositions } from '../../../utils/amenityCardPositions';

const Amenities: React.FC = () => {
  const { t } = useTranslation();
  const { activeAmenitiesButtons, setActiveAmenitiesButtons, setMapCenter, mapCenter } = useContext(PageContext);

  const containerRef = useRef<HTMLDivElement>(null);

  const toggleButton = (name: string) => {
    setActiveAmenitiesButtons((prevButtons) => {
      if (!prevButtons[name]) {
        if (
          mapCenter.lng !== residencyPageAmenityPositions[name][0] &&
          mapCenter.lat !== residencyPageAmenityPositions[name][1]
        ) {
          setMapCenter({ lng: residencyPageAmenityPositions[name][0], lat: residencyPageAmenityPositions[name][1] });
        }
      }

      // Basculez l'état du bouton
      return {
        ...prevButtons,
        [name]: !prevButtons[name],
      };
    });
  };

  const amenitiesButtonNames = ['park', 'retail', 'mosque', 'school'];

  return (
    <div className={styles.container} ref={containerRef}>
      {amenitiesButtonNames.map((name, index) => (
        <AmenitiesButton
          key={index}
          name={t(`amenities.${name}`)}
          isActive={activeAmenitiesButtons[name]}
          toggleActive={() => toggleButton(name)}
        />
      ))}
    </div>
  );
};

export default Amenities;
