// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapFiltersButton_container__FKtvJ {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000080;
  border-radius: 16px;
  padding: 16px 0px;
  min-width: 100px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.MapFiltersButton_container__FKtvJ:hover {
  background-color: #000000;
}

.MapFiltersButton_text__2gDAX {
  font-size: var(--font-size-4);
  color: #fff;
  font-family: var(--font-semi-bold), sans-serif;
  margin-top: 8px;
}

.MapFiltersButton_activeText__vPXvF {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MapFiltersButton/MapFiltersButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,8CAA8C;EAC9C,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  border: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background-color: #00000080;\n  border-radius: 16px;\n  padding: 16px 0px;\n  min-width: 100px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.container:hover {\n  background-color: #000000;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  color: #fff;\n  font-family: var(--font-semi-bold), sans-serif;\n  margin-top: 8px;\n}\n\n.activeText {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MapFiltersButton_container__FKtvJ`,
	"text": `MapFiltersButton_text__2gDAX`,
	"activeText": `MapFiltersButton_activeText__vPXvF`
};
export default ___CSS_LOADER_EXPORT___;
