import styles from './NavigationTab.module.css';
import '../../../style/fonts.css';
import BackButton from '../BackButton/BackButton';
import BuergerMenu from '../BuergerMenu/BuergerMenu';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import NavigationTabButton from '../NavigationTabButton/NavigationTabButton';
import { PageContext } from '../../../contexts';
import MapFilters from '../MapFilters/MapFilters';
import MobileFilters from '../MobileFilters/MobileFilters';
import Amenities from '../Amenities/Amenities';
import MobileAmenities from '../MobileAmenities/MobileAmenities';
import MobileFilterServices from '../MobileFilterServices/MobileFilterServices';
import HousePlanOptions from '../HousePlanOptions/HousePlanOptions';
import { isMobile as isDeviceMobile, isMobile } from 'react-device-detect';
import HorizontalTabletRoomTab from '../HorizontalTabletRoomTab/HorizontalTabletRoomTab';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

interface Props {
  locationName: string;
}

const NavigationTab: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentLanguage = i18n.language;
  const [isTabletLandscape, setIsTabletLandscape] = useState(false);
  const { activeButton, selectedFilter, setSelectedFilter, pageTransitionTiming, setActiveButton, isVRMode } =
    useContext(PageContext);

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [buttonsPage, setButtonsPage] = useState<string[]>([]);

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
    // display: isVRMode ? 'flex' : 'flex',
  };

  useEffect(() => {
    const checkOrientation = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > height && isDeviceMobile) {
        setIsTabletLandscape(true);
      } else {
        setIsTabletLandscape(false);
      }
    };

    // Add the event listener
    window.addEventListener('resize', checkOrientation);

    // Call the function once to check the initial orientation
    checkOrientation();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  const slideUpVariants = {
    hidden: {
      opacity: 0,
      y: '100%',
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: '100%',
    },
  };

  // Handle button click to set active button
  const handleButtonClick = (buttonName: string) => {
    if (activeButton === buttonName) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonName);
    }
  };

  // Close mobile amenities and reset selected filter
  const handleCloseMobileAmenities = () => {
    setSelectedFilter(null);
  };

  // Reset the selected filter when switching to desktop
  useEffect(() => {
    if (!isMobile) {
      setSelectedFilter(null);
    }
  }, [setSelectedFilter]);

  // Common buttons that appear on all pages
  const commonButtons = [t(`nav.share`), t(`nav.language`), t(`nav.callback`), t(`nav.help`)];
  // const commonButtons = [t(`nav.share`), t(`nav.callback`), t(`nav.help`)];

  useEffect(() => {
    const buttonsConfig: Record<string, string[]> = {
      '/globe': [],
      '/city': [t(`nav.filters`), t(`nav.radius`)],
      // '/community': [t(`nav.amenities`)],
      '/masterplan': [t(`nav.filters`), t(`nav.amenities`)],
      '/house-plan': [],
    };

    const pageTimeout = setTimeout(() => {
      setButtonsPage(buttonsConfig[location.pathname] || []);
    }, pageTransitionTiming);

    return () => clearTimeout(pageTimeout);
  }, [location.pathname, t, pageTransitionTiming]);

  // Render navigation buttons based on the current page and common buttons
  const renderNavigationButtons = () => {
    const allButtons = [...buttonsPage, ...commonButtons];

    return allButtons.map((buttonName) => (
      <React.Fragment key={buttonName}>
        <NavigationTabButton
          key={buttonName}
          name={buttonName}
          onClick={() => handleButtonClick(buttonName)}
          isActive={activeButton === buttonName}
        />
        {/* Insert separator after "Language" button */}
        {buttonName === t(`nav.language`) && <div className={styles.separator}></div>}
      </React.Fragment>
    ));
  };

  // Render mobile-specific content based on the current page
  const renderMobileContent = () => {
    if (location.pathname === '/globe') {
      return (
        <>
          <div style={{ width: '35px' }}></div>
          <div className={styles.locationName}>{props.locationName}</div>
          <BuergerMenu />
        </>
      );
    }
    return (
      <>
        <BackButton />
        <div className={styles.locationName}>{props.locationName}</div>
        <BuergerMenu />
      </>
    );
  };

  return (
    <div className={styles.mainWrapper}>
      {/* Render MapFilters if conditions are met */}
      <AnimatePresence>
        {activeButton === t(`nav.filters`) && location.pathname !== '/masterplan' && (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <MapFilters />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Render Amenities for non-mobile and if activeButton is "Amenities" */}
      <AnimatePresence>
        {activeButton === t(`nav.amenities`) && !isMobile && (
          <motion.div
            variants={slideUpVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            <Amenities />
          </motion.div>
        )}
      </AnimatePresence>

      {/* Render mobile-specific components based on selectedFilter and currentPage */}
      {(location.pathname === '/city' ||
        location.pathname === '/community' ||
        location.pathname === '/masterplan' ||
        location.pathname === '/house-plan') && (
        <>
          {location.pathname === '/house-plan' ? (
            isDeviceMobile && isTabletLandscape ? (
              <HorizontalTabletRoomTab />
            ) : (
              <HousePlanOptions onSelectOption={setSelectedOption} selectedOption={selectedOption} />
            )
          ) : (
            <>
              {!isTabletLandscape && selectedFilter === 'MobileAmenities' && (
                <MobileAmenities onClose={handleCloseMobileAmenities} />
              )}
              {!isTabletLandscape && selectedFilter === 'MobileFilters' && (
                <MobileFilterServices onClose={handleCloseMobileAmenities} />
              )}
              {selectedFilter !== 'MobileAmenities' && selectedFilter !== 'MobileFilters' && (
                <MobileFilters
                  currentPage={location.pathname}
                  selectedFilter={selectedFilter}
                  onSelectFilter={setSelectedFilter}
                  setActiveButton={setActiveButton}
                  activeButton={activeButton as string}
                />
              )}
            </>
          )}
        </>
      )}

      <div className={styles.container} style={containerStyle}>
        {isMobile || isTabletLandscape ? renderMobileContent() : renderNavigationButtons()}
      </div>
    </div>
  );
};

export default NavigationTab;
