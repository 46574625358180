import { CSSProperties, useContext, useState } from 'react';
import { PageContext } from '../../../contexts';
import { CloseIcon } from '../../../icons/icons';
import styles from './TabletUnitInformations.module.css';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const TabletUnitInformations: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const { resetButtons, setActiveButton, displayedProperty, toggleVRMode } = useContext(PageContext);
  const [selectedAreaUnit, setSelectedAreaUnit] = useState('SQM');
  const [selectedCurrency, setSelectedCurrency] = useState('SAR');

  const handleClose = () => {
    setActiveButton(null);
  };

  const handleFlooerPlanClick = () => {
    navigate(`/house-plan?property=${displayedProperty?.id}`);
    resetButtons();
    toggleVRMode();
  };

  const handleVRClick = () => {
    navigate(`/house-plan?property=${displayedProperty?.id}`);
    resetButtons();
    toggleVRMode();
  };

  const handleRegisterClick = () => {
    setActiveButton('register');
  };

  if (!isMobile) {
    return null;
  }

  const containerStyle: CSSProperties & { [key: string]: any } = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  const closeButtonStyle: CSSProperties & { [key: string]: any } = {
    left: currentLanguage === 'ar' ? '4%' : 'unset',
    right: currentLanguage === 'ar' ? 'unset' : '4%',
  };

  const titleWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const descriptionStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const priceWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const buttonWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  const roomsWrapperStyle: CSSProperties & { [key: string]: any } = {
    marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
    marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <button className={styles.closeButton} style={closeButtonStyle} onClick={handleClose}>
        <CloseIcon color="#fff" />
      </button>
      <div className={styles.titleWrapper} style={titleWrapperStyle}>
        <div className={styles.availability}>{t(`propertyInformations.available`)}</div>
        <div className={styles.title}>{t(`propertyInformations.unit`)}</div>
      </div>
      <p className={styles.description} style={descriptionStyle}>
        {t(`propertyInformations.description`)}
      </p>
      <div className={styles.roomsWrapper} style={roomsWrapperStyle}>
        <div className={styles.roomItem}>
          <span className={styles.rommName}>{t(`propertyInformations.bedrooms`)}</span>
          <span className={styles.rommNamevalue}>4</span>
        </div>
        <div className={styles.roomItem}>
          <span className={styles.rommName}>{t(`propertyInformations.bathrooms`)}</span>
          <span className={styles.rommNamevalue}>5</span>
        </div>
        <div className={styles.roomItem}>
          <span className={styles.rommName}>{t(`propertyInformations.floor`)}</span>
          <span className={styles.rommNamevalue}>3</span>
        </div>
        <div className={styles.totalArea}>
          <span className={styles.rommName}>{t(`propertyInformations.totalArea`)}</span>
          <div className={styles.selectWrapper}>
            <span className={styles.rommNamevalue}>230.50</span>
            <select
              value={selectedAreaUnit}
              onChange={(e) => setSelectedAreaUnit(e.target.value)}
              className={styles.areaSelect}
            >
              <option value="SQM">SQM</option>
              <option value="SQFT">SQFT</option>
            </select>
          </div>
        </div>
      </div>
      <ImageCarousel
        images={[
          '/assets/images/carrousel-image1.jpeg',
          '/assets/images/carrousel-image2.jpg',
          '/assets/images/carrousel-image3.jpg',
          '/assets/images/carrousel-image4.jpg',
          '/assets/images/carrousel-image5.jpg',
          '/assets/images/carrousel-image6.jpg',
          '/assets/images/carrousel-image7.jpg',
        ]}
      />
      <div className={styles.priceWrapper} style={priceWrapperStyle}>
        <div className={styles.price}>{t(`propertyInformations.price`)}</div>
        <div className={styles.priceAmountWrapper}>
          <div className={styles.priceAmount}>2,537,500</div>
          <select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            className={styles.currencySelect}
          >
            <option value="SAR">SAR</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
      </div>
      <div className={styles.buttonWrapper} style={buttonWrapperStyle}>
        <button className={styles.register} onClick={handleFlooerPlanClick}>
          {t(`button.unitFloorPlan`)}
        </button>
        <button className={styles.register} onClick={handleVRClick}>
          {t(`button.exploreVR`)}
        </button>
        <button className={styles.callback} onClick={handleRegisterClick}>
          {t(`button.register`)}
        </button>
      </div>
    </div>
  );
};

export default TabletUnitInformations;
