import { useContext } from 'react'
import { PageContext } from '../contexts'

export type Params = {
    value?: number
    option?: string
}

export default function useConvertValues(props: Params): string {
    const { currencies } = useContext(PageContext)
    const { value, option } = props

    if (value && option) {
        const formatedOption = option.toLowerCase()

        if (formatedOption === 'sqft') {
            return (value * 10.7639).toFixed(2)
        } else if (formatedOption === 'sqm') {
            return value.toFixed(2)
        } else {
            return (value * currencies[formatedOption]).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
    } else {
        return ''
    }
}
