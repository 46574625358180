interface AmenityPositions {
  [key: string]: number[];
}

interface AmenityCardProps {
  key: string;
  name: string;
  iconType: string;
  position: number[];
}

export const communityPageAmenityPositions: AmenityPositions = {
  // hospital: [-150, 10],
  park: [-150, 11],
  retail: [-150, 12],
  // pocketPark: [-100, 13],
  mosque: [-150, 14],
  school: [-150, 15],
};

export const residencyPageAmenityPositions: AmenityPositions = {
  // hospital: [-150, 10],
  park: [44.39907084516539, 28.367360970959997],  
  retail: [80.3463364701654, 26.495284518993586],  
  // pocketPark: [-3.8, 37],
  mosque: [-7.893881057565855, -30.187262879969843], 
  school: [4.04296875, 28.729130483430154],

   
};

export function getAmenityCards(
  activeAmenitiesButtons: { [key: string]: boolean },
  amenityPositions: AmenityPositions
): AmenityCardProps[] {
  return Object.keys(activeAmenitiesButtons)
    .filter((key) => activeAmenitiesButtons[key])
    .map((key) => ({
      key,
      name: key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()),
      iconType: key,
      position: amenityPositions[key] || { top: 0, left: 0 },
    }));
}
