import React, { useContext, useState } from 'react';
import styles from './FloorTab.module.css';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../../contexts';

const FloorTab: React.FC = () => {
  const { t } = useTranslation();
  const { currentFloor, setCurrentFloor } = useContext(PageContext);

  const buttons = [
    { label: t(`button.groundFloor`), key: 0 },
    { label: t(`button.firstFloor`), key: 1 },
    { label: t(`button.roofFloor`), key: 2 },
  ];

  const handleButtonClick = (index: number) => {
    setCurrentFloor(index);
  };

  return (
    <div className={styles.container}>
      {buttons.reverse().map((button) => (
        <button
          key={button.key}
          className={currentFloor === button.key ? styles.selectedButton : styles.button}
          onClick={() => handleButtonClick(button.key)}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default FloorTab;
