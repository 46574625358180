// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PropertyInformationsButton_container__gA59l {
  position: absolute;
  background-color: #00000080;
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  top: 1%;
  cursor: pointer;
  gap: 12px;
  transition: background-color 0.3s ease;
}

.PropertyInformationsButton_container__gA59l:hover {
  background-color: #111018;
}

.PropertyInformationsButton_text__0LMG9 {
  font-size: var(--font-size-4);
  font-family: var(--font-semi-bold), sans-serif;
  color: #fff;
}

.PropertyInformationsButton_iconWrapper__nWD8H {
  background-color: #34343a;
  padding: 4px 8px;
  border-radius: 8px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .PropertyInformationsButton_container__gA59l {
    top: 2%;
  }
}

@media (max-width: 767px) {
  .PropertyInformationsButton_container__gA59l {
    top: 2%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/PropertyInformationsButton/PropertyInformationsButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,OAAO;EACP,eAAe;EACf,SAAS;EACT,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,8CAA8C;EAC9C,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,OAAO;EACT;AACF;;AAEA;EACE;IACE,OAAO;EACT;AACF","sourcesContent":[".container {\n  position: absolute;\n  background-color: #00000080;\n  border: none;\n  border-radius: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 8px 12px;\n  top: 1%;\n  cursor: pointer;\n  gap: 12px;\n  transition: background-color 0.3s ease;\n}\n\n.container:hover {\n  background-color: #111018;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  font-family: var(--font-semi-bold), sans-serif;\n  color: #fff;\n}\n\n.iconWrapper {\n  background-color: #34343a;\n  padding: 4px 8px;\n  border-radius: 8px;\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .container {\n    top: 2%;\n  }\n}\n\n@media (max-width: 767px) {\n  .container {\n    top: 2%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PropertyInformationsButton_container__gA59l`,
	"text": `PropertyInformationsButton_text__0LMG9`,
	"iconWrapper": `PropertyInformationsButton_iconWrapper__nWD8H`
};
export default ___CSS_LOADER_EXPORT___;
