import { CSSProperties, useContext, useEffect, useRef } from 'react';
import { PageContext } from '../../../contexts';
import { CallbackIcon, HelpIcon, LanguageIcon, ShareIcon } from '../../../icons/icons';
import styles from './MobileMenu.module.css';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const MobileMenu: React.FC = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { setActiveButton, toggleBurgerMenu } = useContext(PageContext); // Import toggleBurgerMenu
  const menuRef = useRef<HTMLDivElement>(null);
  // Determine if the device is a tablet based on window width
  const isTablet = window.innerWidth > 767;

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        toggleBurgerMenu(); // Use toggleBurgerMenu to close the menu
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, toggleBurgerMenu]); // Add toggleBurgerMenu to dependency array

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  const handleLanguageClick = () => {
    const newLanguage = i18n.language === 'en' ? 'ar' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
  };

  const copyrightStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  };

  const menuItemStyle: CSSProperties = {
    flexDirection: isTablet ? 'column' : currentLanguage === 'ar' ? 'row-reverse' : 'row',
  };

  if (!isMobile) {
    return null;
  }

  return (
    <div className={styles.container} ref={menuRef}>
      <div className={styles.menuItemWrapper} style={containerStyle}>
        <div className={styles.menuItem} style={menuItemStyle} onClick={() => handleButtonClick(t(`nav.share`))}>
          <ShareIcon />
          <div className={styles.menuItemText}>{t(`nav.share`)}</div>
        </div>
        <div className={styles.menuItem} style={menuItemStyle} onClick={handleLanguageClick}>
          <LanguageIcon />
          <div className={styles.menuItemText}>{t(`nav.language`)}</div>
        </div>
        <div className={styles.menuItem} style={menuItemStyle} onClick={() => handleButtonClick(t(`nav.callback`))}>
          <CallbackIcon color="#fff" />
          <div className={styles.menuItemText}>{t(`nav.callback`)}</div>
        </div>
        <div className={styles.menuItem} style={menuItemStyle} onClick={() => handleButtonClick(t(`nav.help`))}>
          <HelpIcon color="#fff" />
          <div className={styles.menuItemText}>{t(`nav.help`)}</div>
        </div>
      </div>
      <a
        className={styles.copyright}
        style={copyrightStyle}
        href="http://www.virtualvisions.studio/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t(`copyright`)}
      </a>
    </div>
  );
};

export default MobileMenu;
