// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HousePlanPage_reset__HOWwC {
  position: relative;
  z-index: -2;
}

.HousePlanPage_noBackground__4UL8q {
  background-image: none;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.HousePlanPage_container__Ij5Z3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  background-color: transparent;
}

.HousePlanPage_animatedContainer__UXVze {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 3000;
  width: 25%;
}

.HousePlanPage_container__Ij5Z3 > * {
  pointer-events: auto;
}

.HousePlanPage_backgroundIframe__UQR8R {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  padding: 0;
  z-index: 0;
}

@supports (height: 100svh) {
  .HousePlanPage_backgroundIframe__UQR8R {
    height: 100svh;
  }
}

@supports not (height: 100svh) {
  .HousePlanPage_backgroundIframe__UQR8R {
    height: 100vh;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .HousePlanPage_animatedContainer__UXVze {
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (max-width: 767px) {
  .HousePlanPage_animatedContainer__UXVze {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HousePlanPage/HousePlanPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,kCAAkC;EAClC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,UAAU;EACV,UAAU;AACZ;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,wBAAmB;IAAnB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".reset {\n  position: relative;\n  z-index: -2;\n}\n\n.noBackground {\n  background-image: none;\n  height: calc(var(--vh, 1vh) * 100);\n  overflow: hidden;\n}\n\n.container {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  pointer-events: none;\n  background-color: transparent;\n}\n\n.animatedContainer {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  z-index: 3000;\n  width: 25%;\n}\n\n.container > * {\n  pointer-events: auto;\n}\n\n.backgroundIframe {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  border: none;\n  padding: 0;\n  z-index: 0;\n}\n\n@supports (height: 100svh) {\n  .backgroundIframe {\n    height: 100svh;\n  }\n}\n\n@supports not (height: 100svh) {\n  .backgroundIframe {\n    height: 100vh;\n  }\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .animatedContainer {\n    height: fit-content;\n  }\n}\n\n@media (max-width: 767px) {\n  .animatedContainer {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset": `HousePlanPage_reset__HOWwC`,
	"noBackground": `HousePlanPage_noBackground__4UL8q`,
	"container": `HousePlanPage_container__Ij5Z3`,
	"animatedContainer": `HousePlanPage_animatedContainer__UXVze`,
	"backgroundIframe": `HousePlanPage_backgroundIframe__UQR8R`
};
export default ___CSS_LOADER_EXPORT___;
