import { CSSProperties, useContext, useEffect, useRef } from 'react';
import AmenitiesButton from '../AmenitiesButton/AmenitiesButton';
import styles from './MobileAmenities.module.css';
import { CloseAmenitiesIcon } from '../../../icons/icons';
import { useTranslation } from 'react-i18next';
import { PageContext } from '../../../contexts';
import { residencyPageAmenityPositions } from '../../../utils/amenityCardPositions';

interface Props {
  onClose: () => void;
}

const MobileAmenities: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const { activeAmenitiesButtons, setActiveAmenitiesButtons, setMapCenter, mapCenter } = useContext(PageContext);

  useEffect(() => {
    // Function to handle touch events
    const handleTouchStart = (e: TouchEvent) => {
      if (sliderRef.current && !sliderRef.current.contains(e.target as Node)) {
        props.onClose();
      }
    };

    // Add touchstart event listener
    document.addEventListener('touchstart', handleTouchStart);

    // Cleanup
    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentLanguage === 'ar' && sliderRef.current) {
      const sliderElement = sliderRef.current;
      sliderElement.scrollLeft = sliderElement.scrollWidth;
    }
  }, [currentLanguage]);

  const toggleButton = (name: string) => {
    setActiveAmenitiesButtons((prevButtons) => {
      if (!prevButtons[name]) {
        if (
          mapCenter.lng !== residencyPageAmenityPositions[name][0] &&
          mapCenter.lat !== residencyPageAmenityPositions[name][1]
        ) {
          setMapCenter({ lng: residencyPageAmenityPositions[name][0], lat: residencyPageAmenityPositions[name][1] });
        }
      }

      // Basculez l'état du bouton
      return {
        ...prevButtons,
        [name]: !prevButtons[name],
      };
    });
  };

  const amenitiesButtonNames = ['park', 'retail', 'mosque', 'school'];

  const closeButtonStyle: CSSProperties = {
    marginRight: currentLanguage === 'ar' ? 'unset' : '8px',
    marginLeft: currentLanguage === 'ar' ? '8px' : 'unset',
  };

  const closeButton = (
    <button className={styles.closeButton} style={closeButtonStyle} onClick={props.onClose}>
      <CloseAmenitiesIcon color="#111018" />
    </button>
  );

  const amenitiesButtons = amenitiesButtonNames.map((name, index) => (
    <AmenitiesButton
      key={index}
      name={t(`amenities.${name}`)}
      isActive={activeAmenitiesButtons[name]}
      toggleActive={() => toggleButton(name)}
    />
  ));

  const sliderItems = [closeButton, ...amenitiesButtons];

  const displayItems = currentLanguage === 'ar' ? [...sliderItems].reverse() : sliderItems;

  return (
    <div className={styles.listContainer} ref={sliderRef}>
      <ul className={styles.list}>
        {displayItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default MobileAmenities;
