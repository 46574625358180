// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalTabletRoomTab_container__Ds\\+jR {
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 60%;
  height: 40px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1%;
}

.HorizontalTabletRoomTab_button__CNuAp {
  background-color: #00000050;
  cursor: pointer;
  border: none;
  color: #b8b8b6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 19%;
  border-radius: 12px;
  padding: 8px;
  font-size: var(--font-size-5);
  font-weight: 500;
}

.HorizontalTabletRoomTab_selectedButton__B6V0\\+ {
  background: linear-gradient(to right, #d27bfa, #3d55f8);
  color: #fff;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 19%;
  border-radius: 12px;
  font-size: var(--font-size-5);
  font-weight: bold;
  padding: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/HorizontalTabletRoomTab/HorizontalTabletRoomTab.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,UAAU;EACV,YAAY;EACZ,2BAA2B;EAC3B,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,YAAY;EACZ,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,uDAAuD;EACvD,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,UAAU;EACV,mBAAmB;EACnB,6BAA6B;EAC7B,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".container {\n  position: absolute;\n  bottom: 100%;\n  left: 50%;\n  width: 60%;\n  height: 40px;\n  transform: translateX(-50%);\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  padding-bottom: 1%;\n}\n\n.button {\n  background-color: #00000050;\n  cursor: pointer;\n  border: none;\n  color: #b8b8b6;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 19%;\n  border-radius: 12px;\n  padding: 8px;\n  font-size: var(--font-size-5);\n  font-weight: 500;\n}\n\n.selectedButton {\n  background: linear-gradient(to right, #d27bfa, #3d55f8);\n  color: #fff;\n  cursor: pointer;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 19%;\n  border-radius: 12px;\n  font-size: var(--font-size-5);\n  font-weight: bold;\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HorizontalTabletRoomTab_container__Ds+jR`,
	"button": `HorizontalTabletRoomTab_button__CNuAp`,
	"selectedButton": `HorizontalTabletRoomTab_selectedButton__B6V0+`
};
export default ___CSS_LOADER_EXPORT___;
