// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BreadCrumb_container__mOH7W {
  background-color: #00000080;
  height: 100%;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  box-sizing: border-box;
}

.BreadCrumb_separator__Tp3b2 {
  width: 1.8px;
  height: 16px;
  background-color: #8c8a8880;
  margin: 0px 12px;
}

.BreadCrumb_arrowWrapper__Vicdm {
  margin: 0px 12px;
}

.BreadCrumb_inactiveText__D-7ni {
  font-size: var(--font-size-4);
  color: #8c8a88;
  font-family: var(--font-semi-bold), sans-serif;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-top: 5px;
}

.BreadCrumb_inactiveText__D-7ni:hover {
  color: #fff;
}

.BreadCrumb_activeText__NAEbx {
  font-size: var(--font-size-4);
  color: #fff;
  font-family: var(--font-semi-bold), sans-serif;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 5px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .BreadCrumb_container__mOH7W {
    display: none;
  }
}

@media (max-width: 993px) {
  .BreadCrumb_container__mOH7W {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/BreadCrumb/BreadCrumb.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,8CAA8C;EAC9C,mBAAmB;EACnB,eAAe;EACf,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;EAC7B,WAAW;EACX,8CAA8C;EAC9C,mBAAmB;EACnB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  background-color: #00000080;\n  height: 100%;\n  border-radius: 14px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 12px;\n  box-sizing: border-box;\n}\n\n.separator {\n  width: 1.8px;\n  height: 16px;\n  background-color: #8c8a8880;\n  margin: 0px 12px;\n}\n\n.arrowWrapper {\n  margin: 0px 12px;\n}\n\n.inactiveText {\n  font-size: var(--font-size-4);\n  color: #8c8a88;\n  font-family: var(--font-semi-bold), sans-serif;\n  white-space: nowrap;\n  cursor: pointer;\n  transition: color 0.3s ease;\n  margin-top: 5px;\n}\n\n.inactiveText:hover {\n  color: #fff;\n}\n\n.activeText {\n  font-size: var(--font-size-4);\n  color: #fff;\n  font-family: var(--font-semi-bold), sans-serif;\n  white-space: nowrap;\n  cursor: pointer;\n  margin-top: 5px;\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .container {\n    display: none;\n  }\n}\n\n@media (max-width: 993px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BreadCrumb_container__mOH7W`,
	"separator": `BreadCrumb_separator__Tp3b2`,
	"arrowWrapper": `BreadCrumb_arrowWrapper__Vicdm`,
	"inactiveText": `BreadCrumb_inactiveText__D-7ni`,
	"activeText": `BreadCrumb_activeText__NAEbx`
};
export default ___CSS_LOADER_EXPORT___;
