import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { PageProvider } from './contexts';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './style/fonts.css';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { store } from './app/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <PageProvider>
            <App />
          </PageProvider>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
