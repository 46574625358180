import React, { useState } from 'react';
import styles from './ImageCarousel.module.css';
import { ArrowleftIcon, ArrowrightIcon } from '../../../icons/icons';
import i18n from '../../../i18n';

interface ImageCarouselProps {
  images: string[]; // Array of image URLs
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const currentLanguage = i18n.language;
  const [currentIndex, setCurrentIndex] = useState(currentLanguage === 'ar' ? images.length - 1 : 0);

  const handleScroll = (direction: 'left' | 'right') => {
    const newIndex =
      direction === 'left' ? Math.max(currentIndex - 1, 0) : Math.min(currentIndex + 1, images.length - 1);

    setCurrentIndex(newIndex);
  };

  return (
    <div className={styles.carouselContainer}>
      {currentIndex !== 0 && (
        <button className={`${styles.carouselButton} ${styles.leftButton}`} onClick={() => handleScroll('left')}>
          <ArrowleftIcon />
        </button>
      )}
      <img className={styles.carouselImage} src={images[currentIndex]} alt={`carousel-${currentIndex}`} />
      {currentIndex !== images.length - 1 && (
        <button className={`${styles.carouselButton} ${styles.rightButton}`} onClick={() => handleScroll('right')}>
          <ArrowrightIcon />
        </button>
      )}
    </div>
  );
};

export default ImageCarousel;
