// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Captions_container__AR-\\+I {
  position: relative;
  width: 3759px;
  height: 1616px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.Captions_caption__yPuST {
  position: absolute;
  color: #fff;
  font-family: var(--font-regular), sans-serif;
  background-color: #2626267d;
  padding: 6px 16px;
  font-size: 40px;
  border-radius: 20px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Captions/Captions.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,cAAc;EACd,4BAA4B;EAC5B,wBAAwB;EACxB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,4CAA4C;EAC5C,2BAA2B;EAC3B,iBAAiB;EACjB,eAAe;EACf,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".container {\n  position: relative;\n  width: 3759px;\n  height: 1616px;\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n}\n\n.caption {\n  position: absolute;\n  color: #fff;\n  font-family: var(--font-regular), sans-serif;\n  background-color: #2626267d;\n  padding: 6px 16px;\n  font-size: 40px;\n  border-radius: 20px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Captions_container__AR-+I`,
	"caption": `Captions_caption__yPuST`
};
export default ___CSS_LOADER_EXPORT___;
