// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackButton_container__yqldg {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.BackButton_text__3RGiu {
  font-size: var(--font-size-4);
  color: #8c8a88;
  font-family: var(--font-semi-bold), sans-serif;
  margin-top: 2px;
  transition: color 0.3s ease;
}

.BackButton_text__3RGiu:hover {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/BackButton/BackButton.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,8CAA8C;EAC9C,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  background-color: transparent;\n  border: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  color: #8c8a88;\n  font-family: var(--font-semi-bold), sans-serif;\n  margin-top: 2px;\n  transition: color 0.3s ease;\n}\n\n.text:hover {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BackButton_container__yqldg`,
	"text": `BackButton_text__3RGiu`
};
export default ___CSS_LOADER_EXPORT___;
