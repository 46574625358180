// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BuergerMenu_container__tVBxF {
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BuergerMenu_text__yuBqg {
  font-size: var(--font-size-4);
  color: #8c8a88;
  margin-top: 4px;
  font-family: var(--font-regular), sans-serif;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/BuergerMenu/BuergerMenu.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,eAAe;EACf,4CAA4C;AAC9C","sourcesContent":[".container {\n  background-color: transparent;\n  border: none;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  font-size: var(--font-size-4);\n  color: #8c8a88;\n  margin-top: 4px;\n  font-family: var(--font-regular), sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BuergerMenu_container__tVBxF`,
	"text": `BuergerMenu_text__yuBqg`
};
export default ___CSS_LOADER_EXPORT___;
