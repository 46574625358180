// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBar_container__cVWJw {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2vh;
  left: 2%;
  height: 5%;
  min-height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  gap: 1%;
  z-index: 1000;
}

@media (min-width: 768px) and (max-width: 991px) {
  .TopBar_container__cVWJw {
    height: 7%;
    top: 2%;
  }
}

@media (max-width: 767px) {
  .TopBar_container__cVWJw {
    height: 7%;
    top: 2vh;
    left: 2vh;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/TopBar/TopBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,uBAAkB;EAAlB,kBAAkB;EAClB,OAAO;EACP,aAAa;AACf;;AAEA;EACE;IACE,UAAU;IACV,OAAO;EACT;AACF;;AAEA;EACE;IACE,UAAU;IACV,QAAQ;IACR,SAAS;EACX;AACF","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  position: absolute;\n  top: 2vh;\n  left: 2%;\n  height: 5%;\n  min-height: 50px;\n  width: fit-content;\n  gap: 1%;\n  z-index: 1000;\n}\n\n@media (min-width: 768px) and (max-width: 991px) {\n  .container {\n    height: 7%;\n    top: 2%;\n  }\n}\n\n@media (max-width: 767px) {\n  .container {\n    height: 7%;\n    top: 2vh;\n    left: 2vh;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TopBar_container__cVWJw`
};
export default ___CSS_LOADER_EXPORT___;
