// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HorizontalTabletHousePlanOptions_container__aQvGN {
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 2%;
  bottom: 12%;
}

.HorizontalTabletHousePlanOptions_button__sUhae {
  border: 2px solid #00000020;
  background-color: #00000080;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  color: #fff;
  font-weight: bold;
  margin-top: 12%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/HorizontalTabletHousePlanOptions/HorizontalTabletHousePlanOptions.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".container {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  left: 2%;\n  bottom: 12%;\n}\n\n.button {\n  border: 2px solid #00000020;\n  background-color: #00000080;\n  border-radius: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 48px;\n  min-width: 48px;\n  color: #fff;\n  font-weight: bold;\n  margin-top: 12%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `HorizontalTabletHousePlanOptions_container__aQvGN`,
	"button": `HorizontalTabletHousePlanOptions_button__sUhae`
};
export default ___CSS_LOADER_EXPORT___;
