import { useContext, useEffect, useState } from 'react'
import MapLocation from '../../components/Shared/MapLocation/MapLocation'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import { PageContext } from '../../contexts'
import styles from './CityPage.module.css'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import { LedarLogoIconSmall, Radius } from '../../icons/icons'
import LocationPin from '../../components/Shared/LocationPin/LocationPin'
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isMobile } from 'react-device-detect'
import {
  MaritimeTrainingCenterLine,
  PrinceAdbualahAlFaisalStadiumLine,
  KingAbdulAzizUniversityLine,
  EffatUniversityLine,
  IbnSinaNationalCollegeForMedicalStudiesLine,
  KingAbdulazizInternationalAirportLine,
  NewJeddahCornicheLine,
  MinistryOfCommerceLine,
  KhuzamPalaceLine,
  MonumentLine,
  CivilDefenseSouthernHousingLine,
  KingAbdulazizHospitalLine,
  SouthMallLine,
  CollegeOfTelecomElectronicsLine,
} from '../cityDistances'
import LocationPinText from '../../components/Shared/LocationPin/LocationPinText'
import Disclamer from '../../components/Shared/Disclamer/Disclamer'

export type LocationPinType = {
  name: string
  type: string
  icon: string
  position: {
    desktop: {
      left: string
      top: string
    }
  }
  localisationData: {
    distance: number
    duration: number
  }
  lineIcon: () => JSX.Element
  lineIconStyle: { width: string; top: string; left: string }
  reversed: boolean
}

const LocationPins = [
  {
    name: 'princeAbdullahAlFaisalStadium',
    type: 'otherAmenities',
    icon: 'stadium',
    position: {
      desktop: { left: '57.2%', top: '64.6%' },
    },
    localisationData: {
      distance: 14.1,
      duration: 14.4,
    },
    lineIcon: () => <PrinceAdbualahAlFaisalStadiumLine />,
    lineIconStyle: { width: '539px', top: '103px', left: '-164px' },
    reversed: false,
  },
  {
    name: 'kingAbdulAzizUniversity',
    type: 'education',
    icon: 'education',
    position: {
      desktop: { left: '56.8%', top: '54.4%' },
    },
    localisationData: {
      distance: 19,
      duration: 22,
    },
    lineIcon: () => <KingAbdulAzizUniversityLine />,
    lineIconStyle: { width: '858px', top: '18px', left: '-279px' },
    reversed: false,
  },
  {
    name: 'effatUniversity',
    type: 'education',
    icon: 'education',
    position: {
      desktop: { left: '54%', top: '59.8%' },
    },
    localisationData: {
      distance: 21,
      duration: 32,
    },
    lineIcon: () => <EffatUniversityLine />,
    lineIconStyle: { width: '960px', top: '90px', left: '-210px' },
    reversed: false,
  },
  {
    name: 'maritimeTrainingCenter',
    type: 'education',
    icon: 'education',
    position: {
      desktop: { left: '50.4%', top: '85.8%' },
    },
    localisationData: {
      distance: 26,
      duration: 33,
    },
    lineIcon: () => <MaritimeTrainingCenterLine />,
    lineIconStyle: { width: '689px', top: '-286px', left: '49px' },
    reversed: true,
  },
  {
    name: 'ibnSinaNationalCollegeForMedicalStudies',
    type: 'education',
    icon: 'education',
    position: {
      desktop: { left: '53.7%', top: '65.8%' },
    },
    localisationData: {
      distance: 18,
      duration: 16,
    },
    lineIcon: () => <IbnSinaNationalCollegeForMedicalStudiesLine />,
    lineIconStyle: { width: '785px', top: '34px', left: '-84px' },
    reversed: false,
  },
  {
    name: 'kingAbdulAzizInternationalAirport',
    type: 'otherAmenities',
    icon: 'airport',
    position: {
      desktop: { left: '48.5%', top: '19.5%' },
    },
    localisationData: {
      distance: 42,
      duration: 36,
    },
    lineIcon: () => <KingAbdulazizInternationalAirportLine />,
    lineIconStyle: { width: '2568px', top: '36px', left: '-737px' },
    reversed: false,
  },
  {
    name: 'newJeddahCorniche',
    type: 'otherAmenities',
    icon: 'beach',
    position: {
      desktop: { left: '41.2%', top: '30.5%' },
    },
    localisationData: {
      distance: 40,
      duration: 42,
    },
    lineIcon: () => <NewJeddahCornicheLine />,
    lineIconStyle: { width: '2663px', top: '152px', left: '-421px' },
    reversed: true,
  },
  {
    name: 'ministryOfCommerce',
    type: 'otherAmenities',
    icon: 'government',
    position: {
      desktop: { left: '52.5%', top: '57.5%' },
    },
    localisationData: {
      distance: 19,
      duration: 19,
    },
    lineIcon: () => <MinistryOfCommerceLine />,
    lineIconStyle: { width: '1138px', top: '92px', left: '-219px' },
    reversed: false,
  },
  {
    name: 'khuzamPalace',
    type: 'otherAmenities',
    icon: 'museum',
    position: {
      desktop: { left: '52.3%', top: '60.5%' },
    },
    localisationData: {
      distance: 21,
      duration: 20,
    },
    lineIcon: () => <KhuzamPalaceLine />,
    lineIconStyle: { width: '1061px', top: '87px', left: '-162px' },
    reversed: false,
  },
  {
    name: 'monument',
    type: 'otherAmenities',
    icon: 'museum',
    position: {
      desktop: { left: '49.5%', top: '76.5%' },
    },
    localisationData: {
      distance: 31,
      duration: 23,
    },
    lineIcon: () => <MonumentLine />,
    lineIconStyle: { width: '782px', top: '-94px', left: '64px' },
    reversed: true,
  },
  {
    name: 'civilDefenseSouthernHousing',
    type: 'otherAmenities',
    icon: 'civilDefense',
    position: {
      desktop: { left: '62.5%', top: '71.5%' },
    },
    localisationData: {
      distance: 16,
      duration: 15,
    },
    lineIcon: () => <CivilDefenseSouthernHousingLine />,
    lineIconStyle: { width: '648px', top: '14px', left: '-510px' },
    reversed: false,
  },
  {
    name: 'kingAbdulAzizHospital',
    type: 'health',
    icon: 'health',
    position: {
      desktop: { left: '52.5%', top: '67.3%' },
    },
    localisationData: {
      distance: 18,
      duration: 18,
    },
    lineIcon: () => <KingAbdulazizHospitalLine />,
    lineIconStyle: { width: '766px', top: '61px', left: '-66px' },
    reversed: true,
  },
  {
    name: 'southMall',
    type: 'retail',
    icon: 'retail',
    position: {
      desktop: { left: '51.9%', top: '64%' },
    },
    localisationData: {
      distance: 18,
      duration: 17,
    },
    lineIcon: () => <SouthMallLine />,
    lineIconStyle: { width: '1000px', top: '70px', left: '-113px' },
    reversed: false,
  },
  {
    name: 'collegeOfTelecom&Electronics',
    type: 'education',
    icon: 'education',
    position: {
      desktop: { left: '50.2%', top: '62.1%' },
    },
    localisationData: {
      distance: 20,
      duration: 17,
    },
    lineIcon: () => <CollegeOfTelecomElectronicsLine />,
    lineIconStyle: { width: '1213px', top: '90px', left: '-143px' },
    reversed: false,
  },
]

export default function CityPage() {
  const { t } = useTranslation()
  const { activeButton, activeFilterButtons } = useContext(PageContext)
  const [currentScale, setCurrentScale] = useState(0.195)
  const [clickedLocation, setClickedLocation] = useState<string | null>(null)
  const [activeLocations, setActiveLocations] = useState<LocationPinType[] | null>()
  const [hoveredLocation, setHoveredLocation] = useState<string | null>(null)

  const handleLocationClick = (locationName: string) => {
    if (clickedLocation === locationName) {
      setClickedLocation(null)
    } else {
      setClickedLocation(locationName)
    }
  }

  const handleMouseOver = (element: HTMLElement, textElement: HTMLElement) => {
    element.style.opacity = '1'
    element.style.strokeWidth = '2'

    textElement.style.visibility = 'visible'
  }

  const handleMouseLeave = (element: HTMLElement, textElement: HTMLElement) => {
    element.style.opacity = '0.4'
    element.style.strokeWidth = '1'

    textElement.style.visibility = 'hidden'
  }

  useEffect(() => {
    if (activeButton === t(`nav.radius`)) {
      const radiuses = document.querySelectorAll('.radius')
      const radiusesText = document.querySelectorAll('.radiusText')

      if (radiuses.length === radiusesText.length) {
        for (let i = 0; i < radiuses.length; i++) {
          ;(radiuses[i] as HTMLElement).addEventListener('mouseover', () =>
            handleMouseOver(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
          )
          ;(radiuses[i] as HTMLElement).addEventListener('mouseleave', () =>
            handleMouseLeave(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
          )
        }
      }
    }

    return () => {
      const radiuses = document.querySelectorAll('.radius')
      const radiusesText = document.querySelectorAll('.radiusText')

      if (radiuses.length === radiusesText.length) {
        for (let i = 0; i < radiuses.length; i++) {
          ;(radiuses[i] as HTMLElement).removeEventListener('mouseover', () =>
            handleMouseOver(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
          )
          ;(radiuses[i] as HTMLElement).removeEventListener('mouseleave', () =>
            handleMouseLeave(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
          )
        }
      }
    }
  }, [activeButton, t])

  const disclamerPosition: Position = {
    mobile: {
      en: {
        top: '14%',
        left: '3%',
      },
      ar: {
        left: '3%',
        top: '7%',
      },
    },
    desktop: {
      en: {
        bottom: '5%',
        right: '2%',
      },
      ar: {
        bottom: '5%',
        left: '2%',
      },
    },
  }

  const compassPosition: Position = {
    mobile: {
      en: {
        top: '8%',
        left: '3%',
      },
      ar: {
        left: '3%',
        top: '2%',
      },
    },
    desktop: {
      en: {
        bottom: '5%',
        left: '2%',
      },
      ar: {
        bottom: '5%',
        right: '2%',
      },
    },
  }

  useEffect(() => {
    const setBodyHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    }

    // Initial set
    setBodyHeight()

    // Update on resize
    window.addEventListener('resize', setBodyHeight)

    // Cleanup
    return () => {
      window.removeEventListener('resize', setBodyHeight)
    }
  }, [])

  useEffect(() => {
    const locations = LocationPins.filter(locationPin => activeFilterButtons[locationPin.type])
    setActiveLocations(locations)
  }, [activeFilterButtons])

  const handleZoom = (ref: ReactZoomPanPinchRef) => {
    setCurrentScale(ref.state.scale)
  }

  useEffect(() => {
    document.documentElement.style.setProperty('--scale', `${currentScale}px`)
  }, [currentScale])

  const height = CSS.supports('height', '100svh') ? '100svh' : '100vh'

  const initialMapPosition = {
    desktop: {
      x: -700,
      y: -600,
    },
    mobile: {
      x: -1450,
      y: -900,
    },
  }

  return (
    <div className={styles.container}>
      <TransformWrapper
        initialScale={0.295}
        minScale={0.195}
        maxScale={0.6}
        smooth
        initialPositionX={isMobile ? initialMapPosition.mobile.x : initialMapPosition.desktop.x}
        initialPositionY={isMobile ? initialMapPosition.mobile.y : initialMapPosition.desktop.y}
        disablePadding={true}
        onZoom={ref => handleZoom(ref)}
      >
        <TransformComponent
          wrapperStyle={{
            maxWidth: '100vw',
            maxHeight: height,
          }}
        >
          <div className={styles.backgroundImage} onClick={() => setClickedLocation(null)}>
            <div
              className={styles.overlay}
              style={{
                opacity: clickedLocation ? 0.7 : 0,
              }}
            />
            {activeButton === t(`nav.radius`) ? (
              <div className={styles.radius}>
                <Radius />
              </div>
            ) : null}
            {activeLocations?.slice().map(locationPin => (
              <div key={`locationcontainer-${locationPin.name}`}>
                <LocationPin
                  onClick={handleLocationClick}
                  onHover={setHoveredLocation}
                  clickedPosition={clickedLocation}
                  type={locationPin.type}
                  name={t(`locationPin.${locationPin.name}`)}
                  icon={locationPin.icon}
                  position={locationPin.position}
                  lineIcon={locationPin.lineIcon}
                  lineIconStyle={locationPin.lineIconStyle}
                  reversed={locationPin.reversed}
                />
                <LocationPinText
                  positionClicked={clickedLocation}
                  type={locationPin.type}
                  name={t(`locationPin.${locationPin.name}`)}
                  position={locationPin.position}
                  localisationData={locationPin.localisationData}
                  hoveredLocation={hoveredLocation}
                />
              </div>
            ))}
            <MapLocation
              targetPage="/masterplan"
              icon={<LedarLogoIconSmall />}
              altContent={
                <img
                  style={{ mixBlendMode: 'hard-light', filter: 'brightness(0.6)' }}
                  src="/assets/images/dar-alsadan.jpeg"
                  alt=""
                />
              }
              position={{
                desktop: { top: '77%', left: '55%' },
              }}
            />
          </div>
        </TransformComponent>
        <NavigationTab locationName={t(`locations.city`)} />
        <HorizontalTaletMobileFilters />
        <Compass position={compassPosition} />
        <Disclamer position={disclamerPosition} />
      </TransformWrapper>
    </div>
  )
}
