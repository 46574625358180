import React, { useState, useRef, useEffect } from 'react';
import styles from './MobileTabletRangeSlider.module.css';
import { ArrowDownIcon, RangeButtonIcon } from '../../../icons/icons';
import { MapFiltersType } from '../../../contexts';
import useConvertValues from '../../../hooks/useConvertValues';

interface Props {
  availability: number[];
  label: string;
  options: string[];
  minValue: number;
  maxValue: number;
  update: (key: MapFiltersType, value: string | number | number[]) => void;
  filterKey: MapFiltersType;
  getMapFilter: (key: MapFiltersType) => string | number | string[] | number[] | undefined;
}

const MobileTabletRangeSlider: React.FC<Props> = (props) => {
  const { availability, options, minValue, maxValue, update, filterKey, getMapFilter } = props;
  const [selectedMinValue, setSelectedMinValue] = useState(minValue);
  const [selectedMaxValue, setSelectedMaxValue] = useState(maxValue);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const minThumbRef = useRef<HTMLDivElement>(null);
  const maxThumbRef = useRef<HTMLDivElement>(null);
  const track = useRef<HTMLDivElement>(null);
  const formattedMinValue = useConvertValues({ value: selectedMinValue, option: selectedOption });
  const formattedMaxValue = useConvertValues({ value: selectedMaxValue, option: selectedOption });

  useEffect(() => {
    const filter = getMapFilter(filterKey) as number[];

    if (Array.isArray(filter) && filter.length > 1) {
      if (minValue !== filter[0] && isFinite(filter[0])) {
        setSelectedMinValue(filter[0]);
      }

      if (maxValue !== filter[1] && isFinite(filter[1])) {
        setSelectedMaxValue(filter[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filter = getMapFilter(filterKey) as number[];

    if (Array.isArray(filter) && filter.length > 1) {
      if (selectedMinValue !== filter[0] || selectedMaxValue !== filter[1]) {
        update(filterKey, [selectedMinValue, selectedMaxValue]);
      }
    }
  }, [selectedMinValue, selectedMaxValue, filterKey, update, getMapFilter]);

  useEffect(() => {
    if (minValue && maxValue) {
      const thumbWidth = minThumbRef.current?.offsetWidth || 0;
      const totalWidth = track.current?.offsetWidth || 0;

      const thumbWidthPercent = (thumbWidth / totalWidth) * 50;
      const minInitialPosition = (0 / (maxValue - minValue)) * 100 - thumbWidthPercent;
      const maxInitialPosition = ((maxValue - minValue) / (maxValue - minValue)) * 100 - thumbWidthPercent;

      if (minThumbRef.current && maxThumbRef.current) {
        minThumbRef.current.style.left = `${minInitialPosition}%`;
        maxThumbRef.current.style.left = `${maxInitialPosition}%`;
      }
    }
  }, [minValue, maxValue, minThumbRef, maxThumbRef]);

  const handleDrag = (e: React.TouchEvent<HTMLDivElement>, thumb: 'min' | 'max') => {
    e.preventDefault();
    if (track.current && minThumbRef.current) {
      const rect = track.current.getBoundingClientRect();
      let x = e.touches[0].pageX - rect.left;
      const thumbWidth = minThumbRef.current.offsetWidth;

      if (thumb === 'max') {
        x = Math.max(0, Math.min(x, rect.width));
      } else {
        x = Math.max(0, Math.min(x, rect.width));
      }

      const value = (x / rect.width) * (maxValue - minValue) + minValue;

      if (thumb === 'min' && value < selectedMaxValue - (thumbWidth / rect.width) * (maxValue - minValue)) {
        const tempValue = Math.max(Math.min(value, selectedMaxValue), minValue);
        setSelectedMinValue(tempValue);
      } else if (thumb === 'max' && value > selectedMinValue + (thumbWidth / rect.width) * (maxValue - minValue)) {
        const tempValue = Math.min(Math.max(value, selectedMinValue), maxValue);
        setSelectedMaxValue(tempValue);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div
        ref={track}
        className="track"
        style={{
          position: 'relative',
          height: '20px',
          background: 'transparent',
        }}
      >
        {availability &&
          availability.map((value, index) => (
            <div
              key={index}
              style={{
                position: 'absolute',
                bottom: 0,
                left: `${(index / availability.length) * 100}%`,
                width: `calc(${100 / availability.length}% - 8px)`,
                marginLeft: '2px',
                marginRight: '2px',
                height: `${value * 100}%`,
                background: '#4F5051',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                opacity: 0.5,
              }}
            />
          ))}
        <div
          style={{
            position: 'absolute',
            left:
              selectedMaxValue && maxValue && minValue && maxValue !== minValue
                ? `${((selectedMinValue - minValue) / (maxValue - minValue)) * 100}%`
                : '0%',
            width:
              selectedMaxValue && selectedMinValue && maxValue && minValue && maxValue !== minValue
                ? `${Math.max(
                    0,
                    Math.min(100, ((selectedMaxValue - selectedMinValue) / (maxValue - minValue)) * 100)
                  )}%`
                : '100%',
            height: '3px',
            bottom: '0px',
            background: '#D27BFA',
          }}
        />
        <div
          className="thumb"
          ref={minThumbRef}
          onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => handleDrag(e, 'min')}
          style={{
            position: 'absolute',
            left:
              selectedMinValue && maxValue && minThumbRef.current && track.current && minValue
                ? `${
                    ((selectedMinValue - minValue) / (maxValue - minValue)) * 100 -
                    (minThumbRef.current.offsetWidth / track.current.offsetWidth) * 50
                  }%`
                : '0%',
            padding: '8px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#D27BFA',
            bottom: '-10px',
          }}
        >
          <RangeButtonIcon />
        </div>
        <div
          className="thumb"
          ref={maxThumbRef}
          onTouchMove={(e: React.TouchEvent<HTMLDivElement>) => handleDrag(e, 'max')}
          style={{
            position: 'absolute',
            left:
              selectedMaxValue && maxValue && maxThumbRef.current && track.current && minValue
                ? `${
                    ((selectedMaxValue - minValue) / (maxValue - minValue)) * 100 -
                    (maxThumbRef.current.offsetWidth / track.current.offsetWidth) * 50
                  }%`
                : '100%',
            padding: '8px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#D27BFA',
            bottom: '-10px',
          }}
        >
          <RangeButtonIcon />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div className={styles.priceRangeValue}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 20,
            }}
          >
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className={styles.option}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <ArrowDownIcon />
          </div>
          {formattedMinValue}
        </div>
        <div className={styles.priceRangeValue}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 20,
            }}
          >
            <select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
              className={styles.option}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <ArrowDownIcon />
          </div>
          {formattedMaxValue}
        </div>
      </div>
    </div>
  );
};

export default MobileTabletRangeSlider;
