// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileFilters_wrapper__hc7Wi {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.MobileFilters_filter__F\\+PM- {
  border: none;
  background-color: #00000080;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  margin-right: 2%;
}

@media (min-width: 993px) {
  .MobileFilters_wrapper__hc7Wi {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/MobileFilters/MobileFilters.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n}\n\n.filter {\n  border: none;\n  background-color: #00000080;\n  border-radius: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 48px;\n  width: 48px;\n  margin-right: 2%;\n}\n\n@media (min-width: 993px) {\n  .wrapper {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `MobileFilters_wrapper__hc7Wi`,
	"filter": `MobileFilters_filter__F+PM-`
};
export default ___CSS_LOADER_EXPORT___;
