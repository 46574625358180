// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FloorTab_container__salGB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 20%;
  width: 8%;
}

.FloorTab_button__bEajN {
  background-color: #00000080;
  cursor: pointer;
  border: none;
  color: #b8b8b6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  width: 100%;
  border-radius: 16px;
  font-size: var(--font-size-5);
  font-family: var(--font-medium), sans-serif;
  transition: background-color 0.3s ease;
}

.FloorTab_button__bEajN:hover {
  background-color: #000000;
  color: #fff;
}

.FloorTab_selectedButton__YYRXr {
  background: linear-gradient(to right, #d27bfa, #3d55f8) !important;
  color: #fff;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  width: 100%;
  border-radius: 16px;
  font-size: var(--font-size-5);
  font-family: var(--font-bold), sans-serif;
}

@media (max-width: 991px) {
  .FloorTab_container__salGB {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/FloorTab/FloorTab.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,2BAA2B;EAC3B,UAAU;EACV,SAAS;EACT,sBAAsB;EACtB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,2BAA2B;EAC3B,eAAe;EACf,YAAY;EACZ,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,6BAA6B;EAC7B,2CAA2C;EAC3C,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kEAAkE;EAClE,WAAW;EACX,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,WAAW;EACX,mBAAmB;EACnB,6BAA6B;EAC7B,yCAAyC;AAC3C;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  position: absolute;\n  left: 2%;\n  top: 50%;\n  transform: translateY(-50%);\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n  height: 20%;\n  width: 8%;\n}\n\n.button {\n  background-color: #00000080;\n  cursor: pointer;\n  border: none;\n  color: #b8b8b6;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30%;\n  width: 100%;\n  border-radius: 16px;\n  font-size: var(--font-size-5);\n  font-family: var(--font-medium), sans-serif;\n  transition: background-color 0.3s ease;\n}\n\n.button:hover {\n  background-color: #000000;\n  color: #fff;\n}\n\n.selectedButton {\n  background: linear-gradient(to right, #d27bfa, #3d55f8) !important;\n  color: #fff;\n  cursor: pointer;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30%;\n  width: 100%;\n  border-radius: 16px;\n  font-size: var(--font-size-5);\n  font-family: var(--font-bold), sans-serif;\n}\n\n@media (max-width: 991px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FloorTab_container__salGB`,
	"button": `FloorTab_button__bEajN`,
	"selectedButton": `FloorTab_selectedButton__YYRXr`
};
export default ___CSS_LOADER_EXPORT___;
