// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationTab_mainWrapper__Abo76 {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  z-index: 100;
}

.NavigationTab_container__8lfFZ {
  background-color: #00000080;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px;
  box-sizing: border-box;
}

.NavigationTab_locationName__c08hA {
  color: #fff;
  font-family: var(--font-semi-bold), sans-serif;
}

.NavigationTab_separator__pVn0O {
  width: 1.5px;
  height: 36px;
  background-color: #cccccc19;
  margin: 0px 2px;
}

@media (min-width: 993px) {
  .NavigationTab_mainWrapper__Abo76 {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .NavigationTab_container__8lfFZ {
    width: -moz-fit-content;
    width: fit-content;
    padding: 6px 5px;
    box-sizing: border-box;
  }
  .NavigationTab_locationName__c08hA {
    font-size: var(--font-size-10);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .NavigationTab_container__8lfFZ {
    width: 80%;
    padding: 14px 12px 10px 12px;
  }
  .NavigationTab_locationName__c08hA {
    font-size: var(--font-size-10);
  }
}

@media (max-width: 767px) {
  .NavigationTab_locationName__c08hA {
    font-size: var(--font-size-8);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/NavigationTab/NavigationTab.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,2BAA2B;EAC3B,UAAU;EACV,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE;IACE,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,uBAAkB;IAAlB,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;EACxB;EACA;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".mainWrapper {\n  position: absolute;\n  bottom: 3%;\n  left: 50%;\n  transform: translateX(-50%);\n  width: 90%;\n  z-index: 100;\n}\n\n.container {\n  background-color: #00000080;\n  border-radius: 14px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 12px 12px;\n  box-sizing: border-box;\n}\n\n.locationName {\n  color: #fff;\n  font-family: var(--font-semi-bold), sans-serif;\n}\n\n.separator {\n  width: 1.5px;\n  height: 36px;\n  background-color: #cccccc19;\n  margin: 0px 2px;\n}\n\n@media (min-width: 993px) {\n  .mainWrapper {\n    position: absolute;\n    bottom: 3%;\n    left: 50%;\n    transform: translateX(-50%);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .container {\n    width: fit-content;\n    padding: 6px 5px;\n    box-sizing: border-box;\n  }\n  .locationName {\n    font-size: var(--font-size-10);\n  }\n}\n\n@media (min-width: 992px) and (max-width: 1199px) {\n  .container {\n    width: 80%;\n    padding: 14px 12px 10px 12px;\n  }\n  .locationName {\n    font-size: var(--font-size-10);\n  }\n}\n\n@media (max-width: 767px) {\n  .locationName {\n    font-size: var(--font-size-8);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainWrapper": `NavigationTab_mainWrapper__Abo76`,
	"container": `NavigationTab_container__8lfFZ`,
	"locationName": `NavigationTab_locationName__c08hA`,
	"separator": `NavigationTab_separator__pVn0O`
};
export default ___CSS_LOADER_EXPORT___;
